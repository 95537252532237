import { Grid } from '@mui/material'
import React from 'react'
import { SpacerTopBottomLarge, SpacerTopBottomSmall } from '../../design/spacer/spacer'
import { Body, Container, SubTitle } from '../../global/global.styles'
import { Helmet } from "react-helmet";

const Attribution = () => {
  return (
    <Container>
      <Helmet>
        <title>The Oyster Studios | Attribution</title>
      </Helmet>
      <h1>Attribution</h1>
      <SpacerTopBottomLarge />
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container sx={{ marginBottom: '16px' }}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '2px solid black' }}>
              <SubTitle>
                Item
              </SubTitle>
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '2px solid black' }}>
              <SubTitle>
                Source / Author
              </SubTitle>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: '8px' }}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <Body>
                Modern Home
              </Body>
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <Body>
                Photo by <a href="https://unsplash.com/@webaliser" target="_blank">Ярослав Алексеенко</a> on <a href="https://unsplash.com/photos/_TPTXZd9mOo" target="_blank">Unsplash</a>
              </Body>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: '8px' }}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <Body>
                Commercial buildings
              </Body>
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <Body>
                Photo by <a href="https://unsplash.com/@seanpollock?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank">Sean Pollock</a> on <a href="https://unsplash.com/photos/PhYq704ffdA?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank">Unsplash</a>
              </Body>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: '8px' }}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <Body>
                Smart Home
              </Body>
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <Body>
                <a href="https://blog.swann.com/what-is-a-smart-home/" target="_blank" title="email icons">Link</a>
              </Body>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: '8px' }}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <Body>
                Farmhouse Villa
              </Body>
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <Body>
                Photo by <a href="https://unsplash.com/@naomi365photography" target="_blank">vu anh</a> on <a href="https://unsplash.com/photos/TiVPTYCG_3E" target="_blank">Unsplash</a>
              </Body>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: '8px' }}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <Body>
                Infrastructure
              </Body>
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <Body>
                Photo by <a href="https://unsplash.com/@abebarrera" target="_blank">Abraham Barrera</a> on <a href="https://unsplash.com/photos/8Nn49K7Snow" target="_blank">Unsplash</a>
              </Body>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: '8px' }}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <Body>
                404 page animation
              </Body>
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <Body>
                https://help.rockcontent.com/en/how-to-solve-404-error
              </Body>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SpacerTopBottomLarge />
      <h2>For any request or grievance, contact below</h2>
      <SpacerTopBottomSmall />
      <ul>
        <li>
          <p>By email: office@srisaiengineers.com</p>
        </li>
        <li>
          <p>By visiting this page on our website: <a href="https://srisaiengineers.com/contact" rel="external nofollow noopener" target="_blank">https://srisaiengineers.com/contact</a></p>
        </li>
        <li>
          <p>By phone number: +91 9380450912</p>
        </li>
        <li>
          <p>By mail: Hennur main road, Bengaluru- 560043</p>
        </li>
      </ul>
    </Container>
  )
}

export default Attribution