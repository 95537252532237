// Defaults
import React from 'react';
import { SpacerTopBottomLarge } from '../../../design/spacer/spacer';
import { AlignCenter, BodyWhiteSB, HeadingItalicsWhiteR, HeadingWhiteSB, Squeeze, SqueezeXL } from '../../../global/global.styles';
import { ProjectContainer } from './projects.styles';

// Ext-libs


// Int-libs


// Main
export const ProjectTile = (props) => {
    return (
        <>
            <ProjectContainer onClick={props.click}>
                <AlignCenter>
                    <HeadingWhiteSB>{props.title}</HeadingWhiteSB>
                    <HeadingItalicsWhiteR>{props.subTitle}</HeadingItalicsWhiteR>
                    <SpacerTopBottomLarge>
                        <SqueezeXL>
                            <BodyWhiteSB>
                                {props.description}
                            </BodyWhiteSB>
                        </SqueezeXL>
                    </SpacerTopBottomLarge>
                </AlignCenter>
            </ProjectContainer>
        </>
    );
};