// Defaults
import React, { useState, useEffect } from 'react';

// Ext-libs
import axios from 'axios';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Int-libs
import { AlignRight, Flexbox, FlexboxSB, FormBackground, FormCircle, FormContainer, FormImage, FormImageLamp, FormImagePot, FormLine, GetStartedFlexbox, GetStartedInput, GetStartedLabel, Heading, Sandwich, SubHeading, Title } from '../../global.styles';
import LampImage from '../../../assets/images/lamp.png'
import PotImage from '../../../assets/images/pot.png'
import { SpacerTopBottomLarge } from '../../../design/spacer/spacer';
import { CtaInput, CtaLabel } from '../../../pages/home/components/home.styles';
import { AspirantFlexbox } from '../../../pages/contact/components/contact.styles';

// Main
export const CtaFormP1 = ({ formData, setFormData, ...props }) => {

    const navigate = useNavigate()

    return (
        <>
            <FormBackground>
                <FormContainer>
                    <FormImageLamp src={LampImage} alt={"Lamp"} />
                    <FormImagePot src={PotImage} alt={"Pot"} />
                    <Flexbox>
                        <Flexbox style={{ margin: '0 auto' }}>
                            <FormCircle style={{ backgroundColor: '#FFE202' }} />
                            <FormLine />
                            <FormCircle />
                            <FormLine />
                            <FormCircle />
                        </Flexbox>
                    </Flexbox>
                    <Sandwich>
                        <SubHeading>
                            Tell us a bit about yourself
                        </SubHeading>
                    </Sandwich>
                    <SpacerTopBottomLarge>
                        <GetStartedFlexbox>
                            <GetStartedLabel>Name:</GetStartedLabel>
                            <GetStartedInput
                                id="outlined-basic"
                                type='text'
                                variant="outlined"
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            />
                        </GetStartedFlexbox>
                    </SpacerTopBottomLarge>
                    <SpacerTopBottomLarge>
                        <GetStartedFlexbox>
                            <GetStartedLabel>Email:</GetStartedLabel>
                            <GetStartedInput
                                id="outlined-basic"
                                type='email'
                                variant="outlined"
                                value={formData.email}
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                            />
                        </GetStartedFlexbox>
                    </SpacerTopBottomLarge>
                    <SpacerTopBottomLarge>
                        <GetStartedFlexbox>
                            <GetStartedLabel>Contact number:</GetStartedLabel>
                            <GetStartedInput
                                id="outlined-basic"
                                type='number'
                                variant="outlined"
                                value={formData.contactNum}
                                onChange={(e) => setFormData({ ...formData, contactNum: e.target.value })}
                            />
                        </GetStartedFlexbox>
                    </SpacerTopBottomLarge>
                    <FlexboxSB>
                        <Button
                            variant="outlined"
                            color='warning'
                            size='large'
                            onClick={() => { navigate('/') }}
                        >
                            Home
                        </Button>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: '#51B960' }}
                            size='large'
                            onClick={props.next}
                        >
                            Next
                        </Button>
                    </FlexboxSB>
                </FormContainer>
            </FormBackground>
        </>
    );
};