import styled from 'styled-components'
import { Link } from "react-router-dom";
import { BottomNavigationAction, TextField } from '@mui/material';

// --------------------------------------- Containers --------------------------------------- //

export const PageStart = styled.div`
    padding-top: 148px;

    @media only screen and (max-width: 650px) {
        padding-top: 0;
    }
`;

export const Squeeze = styled.div`
    padding-left: ${(props) => props.theme.space[18]};
    padding-right: ${(props) => props.theme.space[18]};

    @media only screen and (min-width: 1800px) {
        padding-left: 248px;
        padding-right: 248px;
    }
    
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }

    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }

    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }

    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
`;

export const SqueezeXL = styled.div`
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};

    @media only screen and (min-width: 1800px) {
        padding-left: 264px;
        padding-right: 264px;
    }
    
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[12]};
        padding-right: ${(props) => props.theme.space[12]};
    }

    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }

    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[12]};
        padding-right: ${(props) => props.theme.space[12]};
    }

    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[5]};
        padding-right: ${(props) => props.theme.space[5]};
    }
`;


export const Container = styled.div`
    padding-left: ${(props) => props.theme.space[18]};
    padding-right: ${(props) => props.theme.space[18]};
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};

    @media only screen and (min-width: 1800px) {
        padding-left: 248px;
        padding-right: 248px;
    }

    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }

    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }

    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }

    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
`;

export const ContainerGrey = styled.div`
    padding-left: ${(props) => props.theme.space[18]};
    padding-right: ${(props) => props.theme.space[18]};
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    background-color: ${(props) => props.theme.colors.ui.grey20};

    @media only screen and (min-width: 1800px) {
        padding-left: 248px;
        padding-right: 248px;
    }

    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }

    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }

    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }

    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }

    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
`;

export const ContainerGreen = styled.div`
    padding-left: ${(props) => props.theme.space[18]};
    padding-right: ${(props) => props.theme.space[18]};
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    background-color: ${(props) => props.theme.colors.primary.green20};

    @media only screen and (min-width: 1800px) {
        padding-left: 248px;
        padding-right: 248px;
    }

    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }

    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }

    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }

    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
`;

export const ContainerYellow = styled.div`
    padding-left: ${(props) => props.theme.space[18]};
    padding-right: ${(props) => props.theme.space[18]};
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    background-color: ${(props) => props.theme.colors.primary.yellow20};

    @media only screen and (min-width: 1800px) {
        padding-left: 248px;
        padding-right: 248px;
    }

    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }

    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }

    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }

    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
`;

export const Sandwich = styled.div`
    padding-top: ${(props) => props.theme.space[5]};
    padding-bottom: ${(props) => props.theme.space[5]};
`;

export const SandwichL = styled.div`
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
`;

export const HeadingContainer = styled.div`
    padding-left: ${(props) => props.theme.space[18]};
    padding-right: ${(props) => props.theme.space[18]};

    @media only screen and (min-width: 1800px) {
        padding-left: 248px;
        padding-right: 248px;
    }

    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }

    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }

    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }

    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
`;

export const FlexColumnSB = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

export const Flex = styled.div`
    display: flex;
`;

export const Flexbox = styled.div`
    display: flex;
    align-items: center;
`;

export const FlexboxSB = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const FlexboxSA = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

export const FlexboxSE = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
`;

export const Left = styled.div`
    
`;

export const Middle = styled.div`
    
`;

export const Right = styled.div`
    
`;

export const Top = styled.div`
    
`;

export const Center = styled.div`
    
`;

export const Bottom = styled.div`
    
`;

export const AlignRight = styled.div`
    text-align: right;
`;

export const AlignCenter = styled.div`
    text-align: center;
`;

export const AbsoluteCenter = styled.div`
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
`;

// --------------------------------------- Text --------------------------------------- //

export const Headline = styled.div`
    font-size: ${(props) => props.theme.fontSizes.headline};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.text.grey80};

    @media only screen and (min-width: 1800px) {
        font-size: 176px;
    }

    @media only screen and (max-width: 1025px) {
        font-size: 104px;
    }

    @media only screen and (max-width: 850px) {
        font-size: 96px;
    }

    @media only screen and (max-width: 650px) {
        font-size: 104px;
    }

    @media only screen and (max-width: 450px) {
        font-size: 80px;
    }

    @media only screen and (max-width: 370px) {
        font-size: 75px;
    }

    @media only screen and (max-width: 350px) {
        font-size: 65px;
    }
`;

export const SubHeadline = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeadline};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.text.grey80};
`;

export const Caption = styled.div`
    font-size: ${(props) => props.theme.fontSizes.caption};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.text.grey80};
`;

export const SubCaption = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subCaption};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.text.grey80};

    @media only screen and (min-width: 1800px) {
        font-size: ${(props) => props.theme.fontSizes.subHeadline};
    }

    @media only screen and (max-width: 1025px) {
        font-size: ${(props) => props.theme.fontSizes.heading};
    }

    @media only screen and (max-width: 850px) {
        font-size: 40px;
    }

    @media only screen and (max-width: 650px) {
        font-size: ${(props) => props.theme.fontSizes.subCaption};
    }

    @media only screen and (max-width: 370px) {
        font-size: 32px;
    }
`;

export const Heading = styled.div`
    font-size: ${(props) => props.theme.fontSizes.heading};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.text.grey80};
`;

export const HeadingSB = styled.div`
    font-size: ${(props) => props.theme.fontSizes.heading};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.text.grey80};
`;

export const HeadingItalicsSB = styled.div`
    font-size: ${(props) => props.theme.fontSizes.heading};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.text.grey80};
    font-style: italic;
`;

export const HeadingItalicsB = styled.div`
    font-size: ${(props) => props.theme.fontSizes.heading};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.text.grey80};
    font-style: italic;
`;

export const HeadingWhiteSB = styled.div`
    font-size: ${(props) => props.theme.fontSizes.heading};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.text.white};
`;

export const HeadingItalicsWhiteR = styled.div`
    font-size: ${(props) => props.theme.fontSizes.heading};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-style: italic;
    color: ${(props) => props.theme.colors.text.white};
`;

export const SubHeading = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.text.grey80};
`;

export const Title = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.text.grey80};
`;

export const TitleItalics = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    font-style: italic;
    color: ${(props) => props.theme.colors.text.white};
    line-height: 1.5;
    
    @media only screen and (max-width: 650px) {
        font-size: 20px;
    }

    @media only screen and (max-width: 450px) {
        font-size: 16px;
    }
`;

export const TitleLink = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.accents.indigo};
    cursor: pointer;
`;

export const TitleWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.text.white};
`;

export const SubTitle = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.text.grey80};
`;

export const SubTitleRegular = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey80};
`;

export const Body = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey80};
`;

export const BodyLH2 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey80};
    line-height: 2;
`;

export const BodyJLHmedium = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey80};
    line-height: 1.5;
    text-align: justify;
`;

export const BodyJLH2 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey80};
    line-height: 2;
    text-align: justify;
`;

export const BodyWhiteSB = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.text.white};
`;

// --------------------------------------- Position --------------------------------------- //

export const PositionCenter = styled.div`
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
`;

// --------------------------------------- Navbar --------------------------------------- //

export const NavTitle = styled(Link)`
    font-size: ${(props) => props.theme.fontSizes.title};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.ui.black};
    text-decoration: none;

    @media only screen and (min-width: 1800px) {
        font-size: ${(props) => props.theme.fontSizes.title};
    }
`;

export const NavSubTitle = styled(Link)`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.ui.black};
    text-decoration: none;

    @media only screen and (min-width: 1800px) {
        font-size: ${(props) => props.theme.fontSizes.title};
    }
`;

export const NavSubTitleActive = styled(Link)`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.ui.black};
    text-decoration: none;
    border-bottom: 3px solid black;

    @media only screen and (min-width: 1800px) {
        font-size: ${(props) => props.theme.fontSizes.title};
    }
`;

// --------------------------------------- CTAFORM --------------------------------------- //

export const FormBackground = styled.div`
    background: radial-gradient(circle, rgba(248,247,250,1) 0%, rgba(203,203,212,1) 33%, rgba(166,166,170,1) 66%, rgba(58,58,58,1) 100%);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

export const FormContainer = styled.div`
    margin: 0 auto;
    box-shadow: 0px 0px 3px 1px rgba(180,180,180,0.75);
    -webkit-box-shadow: 0px 0px 3px 1px rgba(180,180,180,0.75);
    -moz-box-shadow: 0px 0px 3px 1px rgba(180,180,180,0.75);
    background-color: ${(props) => props.theme.colors.ui.white};
    padding: ${(props) => props.theme.space[6]};
    position: relative;

    @media only screen and (max-width: 650px) {
        padding: ${(props) => props.theme.space[4]};
    }
`;

export const FormContainerP3 = styled.div`
    margin: 0 auto;
    box-shadow: 0px 0px 3px 1px rgba(180,180,180,0.75);
    -webkit-box-shadow: 0px 0px 3px 1px rgba(180,180,180,0.75);
    -moz-box-shadow: 0px 0px 3px 1px rgba(180,180,180,0.75);
    background-color: ${(props) => props.theme.colors.ui.white};
    padding: ${(props) => props.theme.space[6]};
    position: relative;

    @media only screen and (max-width: 650px) {
        padding: ${(props) => props.theme.space[4]};
    }

    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const GetStartedFlexbox = styled.div`
    display: flex;
    align-items: center;

    @media only screen and (max-width: 450px) {
        display: block;
    }
`;

export const GetStartedFlex = styled.div`
    display: flex;

    @media only screen and (max-width: 450px) {
        display: block;
    }
`;

export const FlexboxSBP3 = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
    }
`;

export const GetStartedSubHeading = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.text.grey80};
`;

export const GetStartedSubHeadingP3 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.text.grey80};

    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[4]};
        padding-right: ${(props) => props.theme.space[4]};
    }
`;

export const GetStartedLabel = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    margin-left: auto;
    margin-right: 16px;

    @media only screen and (min-width: 1800px) {
        margin-left: 15%;
        margin-right: 0;
    }

    @media only screen and (max-width: 450px) {
        font-size: 20px;
        margin-left: 0;
        margin-right: 0;
    }

    @media only screen and (max-width: 380px) {
        font-size: 18px;
    }
`;

export const GetStartedInput = styled(TextField)`
    background-color: white;
    width: 320px;

    @media only screen and (min-width: 1800px) {
        width: 400px;
    }

    @media only screen and (max-width: 450px) {
        width: 100%;
    }
`;

export const FormImageLamp = styled.img`
    position: absolute;
    width: 104px;
    top: 56px;
    left: 10px;

    @media only screen and (max-width: 450px) {
        width: 80px;
        top: 180px;
    }
`;

export const FormImagePot = styled.img`
    position: absolute;
    width: 200px;
    top: 48px;
    right: 0;

    @media only screen and (max-width: 450px) {
        width: 150px;
        top: 180px;
    }
`;

export const FormCircle = styled.div`
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: ${(props) => props.theme.colors.ui.grey40};
`;

export const FormLine = styled.div`
    border-bottom: 1px dotted black;
    width: 144px;

    @media only screen and (max-width: 380px) {
        width: 100px;
    } 
`;

export const FormImageCanvas = styled.img`
    position: absolute;
    width: 120px;
    bottom: 96px;
    left: 120px;

    @media only screen and (max-width: 450px) {
        bottom: 10px;
        left: 20px;
    }
`;

export const FormImagePen = styled.img`
    position: absolute;
    width: 56px;
    top: 104px;
    right: 40px;

    @media only screen and (max-width: 450px) {
        top: 140px;
    }
`;

export const FormRadio = styled.div`
    display: inline-flex;
    overflow: hidden;
    border-radius: 16px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    border: 0.5px solid ${(props) => props.theme.colors.ui.grey40};
    z-index: 1;
    margin: 0 30px;

    @media only screen and (max-width: 650px) {
        margin: 0 15px;
    }

    @media only screen and (max-width: 400px) {
        margin: 0 12px;
    }

    @media only screen and (max-width: 380px) {
        margin: 0 10px;
    }

    @media only screen and (max-width: 370px) {
        margin: 0 5px;
    }

    @media only screen and (max-width: 350px) {
        margin: 0 1px;
    }
`;

export const FormImageClock = styled.img`
    position: absolute;
    width: 120px;
    bottom: 45px;
    left: 50%;
    transform: translate(-50%, -50%);

    @media only screen and (max-width: 650px) {
        bottom: 25px;
    }
`;

export const FormImageRocket = styled.img`
    position: absolute;
    width: 224px;
    top: 120px;
    right: 0;
    opacity: 0.9;

    @media only screen and (max-width: 650px) {
        top: 140px;
    }
`;

export const SuccessHeading = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: #009578;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    @media only screen and (max-width: 650px) {
        font-size: ${(props) => props.theme.fontSizes.title};
        left: 30%;
        transform: translate(-20%, -50%);
    }

    @media only screen and (max-width: 350px) {
        font-size: ${(props) => props.theme.fontSizes.subTitle};
        left: 30%;
        transform: translate(-20%, -80%);
    }
`;

export const FormExit = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: #FF7E2C;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    border: 1px solid #FF7E2C;
    border-radius: 8px;
    padding: ${(props) => props.theme.space[2]};
    cursor: pointer;
    &:hover {
        background-color: ${(props) => props.theme.colors.secondary.orange};
        color: ${(props) => props.theme.colors.text.grey80};
    }

    @media only screen and (max-width: 850px) {
        bottom: 15%;
    }

    @media only screen and (max-width: 650px) {
        bottom: 12%;
    }

    @media only screen and (max-width: 370px) {
        font-size: ${(props) => props.theme.fontSizes.subTitle};
    }

    @media only screen and (max-width: 350px) {
        bottom: 10%;
    }
`;

export const PageExit = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: #30D6B0;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    border: 1px solid #30D6B0;
    border-radius: 8px;
    padding: ${(props) => props.theme.space[2]};
    cursor: pointer;
    &:hover {
        background-color: ${(props) => props.theme.colors.primary.green};
        color: ${(props) => props.theme.colors.text.grey80};
    }

    @media only screen and (max-width: 850px) {
        top: 90%;
    }
`;

// --------------------------------------- Footer --------------------------------------- //

export const FooterCover = styled.div`
    background-color: ${(props) => props.theme.colors.ui.black};
`;

export const FooterBottom = styled.div`
    padding-bottom: ${(props) => props.theme.space[8]};
`;

export const FooterContainer = styled.div`
    padding-left: ${(props) => props.theme.space[18]};
    padding-right: ${(props) => props.theme.space[18]};
    padding-top: ${(props) => props.theme.space[12]};
    padding-bottom: ${(props) => props.theme.space[12]};

    @media only screen and (min-width: 1800px) {
        padding-left: 248px;
        padding-right: 248px;
    }

    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }

    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
`;

export const FooterLeft = styled.div`   
    color: ${(props) => props.theme.colors.ui.grey60};
    position: absolute;
    bottom: 24px;
`;

export const FooterRight = styled.a`   
    color: ${(props) => props.theme.colors.ui.grey60};
    text-decoration: none;
    position: absolute;
    bottom: 24px;
    right: 0;

    &:hover {
        color: white;
    }
`;

export const FooterColumn = styled.div`
    margin-right: 80px;

    @media only screen and (max-width: 1100px) {
        margin-right: 40px;
    }
`;

export const FooterTitle = styled.div`
    color: ${(props) => props.theme.colors.ui.grey20};
    font-size: ${(props) => props.theme.fontSizes.title};
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const FooterSubTitle = styled.div`
    color: ${(props) => props.theme.colors.ui.grey40};
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    cursor: pointer;
`;

export const FooterContact = styled.div`

`;

export const FooterContactBox = styled.div`
    background-color: ${(props) => props.theme.colors.ui.grey80};
    border-top-left-radius: 80px;
    border-bottom-right-radius: 80px;
    padding: ${(props) => props.theme.space[5]};
    width: 400px;

    @media only screen and (max-width: 1100px) {
        width: 100%;
    }

    @media only screen and (max-width: 850px) {
        margin: 0 auto;
    }

    @media only screen and (max-width: 450px) {

    }

    @media only screen and (max-width: 350px) {
        padding: ${(props) => props.theme.space[4]};
    }
`;

export const FooterContactText = styled.div`
    color: ${(props) => props.theme.colors.ui.grey40};
    padding-top: ${(props) => props.theme.space[1]};
    padding-bottom: ${(props) => props.theme.space[1]};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};

`;

export const LastLine = styled.div`
    
`;

export const FooterMenuItemContainer = styled.div`
    padding: ${(props) => props.theme.space[2]};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.ui.grey40};
    font-size: 20px;
    background-color: ${(props) => props.theme.colors.ui.grey80};
    position: relative;
    border-radius: 8px;
    cursor: pointer;
`;

export const MenuItemLeft = styled.div`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.ui.grey40};
    font-size: 20px;
    text-align: center;
    line-height: 1.8;
`;

export const MenuItemRight = styled.div`
    color: ${(props) => props.theme.colors.ui.grey40};
    position: absolute;
    top: ${(props) => props.theme.space[2]};
    right: ${(props) => props.theme.space[2]};
`;




