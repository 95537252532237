// Defaults
import React, { useState } from 'react';
import './ctaForm.css'

// Ext-libs
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import {
    Flexbox,
    FlexboxSB,
    FlexboxSBP3,
    FlexboxSE,
    FormBackground,
    FormCircle,
    FormContainer,
    FormContainerP3,
    FormImageClock,
    FormImageRocket,
    FormLine,
    FormRadio,
    FormRadio2,
    FormRadio3,
    FormRadioLeft,
    FormRadioRight,
    GetStartedSubHeadingP3,
    Sandwich,
    SubHeading
} from '../../global.styles';
import { SpacerLeftRightLarge, SpacerTopBottomLarge } from '../../../design/spacer/spacer';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

// Int-libs
import ClockImage from '../../../assets/images/clock.png'
import RocketImage from '../../../assets/images/rocket.png'

// Main
export const CtaFormP3 = ({ formData, setFormData, ...props }) => {

    const navigate = useNavigate()

    const Radio = ({ label, id, handleChange, name, form, showOptions }) => (
        <>
            <input
                type="radio"
                id={id}
                name={name}
                onChange={handleChange}
                onClick={showOptions}
                value={id}
                checked={form[name] == id}
                className="formRadioInput"
            />
            <label htmlFor={id} className="formRadioLabel">{label}</label>
        </>
    );

    const [form, setFormValue] = useState({
        medium: "",
        dayPreference: "",
        timePreference: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValue((prevFormValues) => ({
            ...prevFormValues,
            [name]: value
        }));
    }

    const handleChangeMedium = (e) => {
        const { name, value } = e.target;
        setFormValue((prevFormValues) => ({
            ...prevFormValues,
            [name]: value
        }));
        setFormData({ ...formData, medium: e.target.value })
    }

    const handleChangeDayPreference = (e) => {
        const { name, value } = e.target;
        setFormValue((prevFormValues) => ({
            ...prevFormValues,
            [name]: value
        }));
        setFormData({ ...formData, dayPreference: e.target.value })
    }

    const handleChangeTimePreference = (e) => {
        const { name, value } = e.target;
        setFormValue((prevFormValues) => ({
            ...prevFormValues,
            [name]: value
        }));
        setFormData({ ...formData, timePreference: e.target.value })
    }

    // const [option, setOptions] = useState("");

    // const offlineOption = () => {
    //     setOptions("offline")
    // }

    // const onlineOption = () => {
    //     setOptions("online")
    // }

    return (
        <>
            <FormBackground>
                <FormContainerP3>
                    <FormImageClock src={ClockImage} alt={"Canvas"} />
                    <FormImageRocket src={RocketImage} alt={"Pen"} />
                    <Flexbox>
                        <Flexbox style={{ margin: '0 auto' }}>
                            <FormCircle style={{ backgroundColor: '#51B960' }} />
                            <FormLine />
                            <FormCircle style={{ backgroundColor: '#51B960' }} />
                            <FormLine />
                            <FormCircle style={{ backgroundColor: '#FFE202' }} />
                        </Flexbox>
                    </Flexbox>
                    <Sandwich>
                        <GetStartedSubHeadingP3>
                            When can we get in touch ?
                        </GetStartedSubHeadingP3>
                    </Sandwich>
                    <SpacerTopBottomLarge>
                        <FlexboxSE>
                            <FormRadio>
                                <Radio
                                    form={form}
                                    name="medium"
                                    label="Offline"
                                    id="Offline"
                                    handleChange={handleChangeMedium}
                                />
                            </FormRadio>
                            <FormRadio>
                                <Radio
                                    form={form}
                                    name="medium"
                                    label="Online"
                                    id="Online"
                                    handleChange={handleChangeMedium}
                                />
                            </FormRadio>
                        </FlexboxSE>
                    </SpacerTopBottomLarge>
                    <SpacerTopBottomLarge>
                        <FormRadio>
                            <Radio
                                form={form}
                                name="dayPreference"
                                label="Anyday"
                                id="Anyday"
                                handleChange={handleChangeDayPreference}
                            />
                        </FormRadio>
                        <FormRadio>
                            <Radio
                                form={form}
                                name="dayPreference"
                                label="Weekday"
                                id="Weekday"
                                handleChange={handleChangeDayPreference}
                            />
                        </FormRadio>
                        <FormRadio>
                            <Radio
                                form={form}
                                name="dayPreference"
                                label="Weekend"
                                id="Weekend"
                                handleChange={handleChangeDayPreference}
                            />
                        </FormRadio>
                    </SpacerTopBottomLarge>
                    <SpacerTopBottomLarge>
                        <FlexboxSE>
                            <FormRadio>
                                <Radio
                                    form={form}
                                    name="timePreference"
                                    label="Anytime"
                                    id="Anytime"
                                    handleChange={handleChangeTimePreference}
                                />
                            </FormRadio>
                            <FormRadio>
                                <Radio
                                    form={form}
                                    name="timePreference"
                                    label="Morning"
                                    id="Morning"
                                    handleChange={handleChangeTimePreference}
                                />
                            </FormRadio>
                        </FlexboxSE>
                    </SpacerTopBottomLarge>
                    <SpacerTopBottomLarge>
                        <FlexboxSE>
                            <FormRadio>
                                <Radio
                                    form={form}
                                    name="timePreference"
                                    label="Afternoon"
                                    id="Afternoon"
                                    handleChange={handleChange}
                                />
                            </FormRadio>
                            <FormRadio>
                                <Radio
                                    form={form}
                                    name="timePreference"
                                    label="Evening"
                                    id="Evening"
                                    handleChange={handleChange}
                                />
                            </FormRadio>
                        </FlexboxSE>
                    </SpacerTopBottomLarge>
                    <FlexboxSBP3>
                        <Button
                            variant="outlined"
                            color='warning'
                            size='large'
                            onClick={props.prev}
                        >
                            Back
                        </Button>
                        {
                            props.loading ?
                                <LoadingButton
                                    loading
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    size='large'
                                >
                                    Submit
                                </LoadingButton>
                                :
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: '#51B960' }}
                                    size='large'
                                    onClick={props.submit}
                                >
                                    Finish
                                </Button>
                        }
                    </FlexboxSBP3>
                </FormContainerP3>
            </FormBackground>
        </>
    );
};