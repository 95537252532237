export const fonts = {
  main: "Lato_400Regular"
};

export const fontWeights = {
  thin: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  black: 900
};

export const fontSizes = {
  headline: "144px",
  subHeadline: "64px",
  caption: "56px",
  subCaption: "48px",
  heading: "40px",
  subHeading: "32px",
  title: "24px",
  subTitle: "16px",
  body: "16px",
  underline:"8px"
};