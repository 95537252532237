// Defaults
import React from 'react';
import { Bottom, Flex, Flexbox, SubTitle, Title, TitleWhite, Top } from '../../../global/global.styles';
import { BlockLeft, BlockRight, CapabilityCardCover, CapabilityImage, CapabilityImageContainer, CCBottom, CCContainer, CCTop, ColumnFlex } from './home.styles';

// Ext-libs


// Int-libs


// Main
export const CapabilityCard = (props) => {
    return (
        <>
            <CCContainer>
                <CapabilityImageContainer>
                    <CapabilityImage
                        src={props.image}
                        alt="Image"
                    />
                </CapabilityImageContainer>
                <CCBottom >
                    <Flex>
                        <BlockLeft />
                        <BlockRight style={{ background: props.bg }} />
                    </Flex>
                    <ColumnFlex style={{ background: props.bg }}>
                        <Top>
                            <TitleWhite>
                                {props.capability}
                            </TitleWhite>
                        </Top>
                    </ColumnFlex>
                </CCBottom>
            </CCContainer>
        </>
    );
};