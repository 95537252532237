import styled from 'styled-components'


export const LogoCover = styled.div`
    padding-left: ${(props) => props.theme.space[6]};
    padding-right: ${(props) => props.theme.space[6]};
    width: 100%;

    @media only screen and (max-width: 1100px) {
        margin: 32px auto;
        width: 50%; 
    }

    @media only screen and (max-width: 850px) {
        margin: 32px auto;
        width: 60%; 
    }

    @media only screen and (max-width: 450px) {
        margin: 32px auto;
        width: 100%; 
    }
`;

export const PeopleBlockContainer = styled.div`
    
    @media only screen and (max-width: 850px) {
        margin: 32px 0;
    }
`;

export const PeopleImageContainer = styled.div`
    margin: 0 auto;
    width: 250px;
`;

export const PeopleImage = styled.img`
    width: 100%;
`;

export const PeopleDetails = styled.div`
    padding-left: ${(props) => props.theme.space[5]};
    padding-right: ${(props) => props.theme.space[5]};
`;

export const PeopleDesignation = styled.div`
    font-size: 18px;
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const AchievementStatBlock = styled.div`
    text-align: center;

    @media only screen and (max-width: 1100px) {
        margin: 0 16px;
    }

    @media only screen and (max-width: 650px) {
        margin: 0 4px;
    }

    @media only screen and (max-width: 450px) {
        margin: 0;
    }

`;

export const AchievementInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-left: ${(props) => props.theme.space[8]};
    padding-right: ${(props) => props.theme.space[8]};

    @media only screen and (max-width: 1100px) {
        flex-direction: row;  
        padding: ${(props) => props.theme.space[2]};
        justify-content: space-evenly;
    }

    @media only screen and (max-width: 450px) {
        flex-direction: column; 
        margin: 16px 0; 
    }
`;
