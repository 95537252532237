// Defaults
import React, { useState } from 'react';

// Ext-libs
import axios from 'axios';
import { AspirantFlexbox, AspirantFlexboxResume, AspirantFormContainer, AspirantInput, AspirantLabel } from './contact.styles';
import { AlignCenter, Body, Flexbox, FlexboxSA, FlexboxSB, SubTitle, Title } from '../../../global/global.styles';
import { SpacerTopBottomLarge } from '../../../design/spacer/spacer';
import { Button } from '@mui/material';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

// Int-libs
import { db, storage } from '../../../database/firebase';
import { FileUpload } from '../../../global/components/fileUpload/fileUpload';
import { HomeCTASuccess, SelectedFileName } from '../../home/components/home.styles';
import { addDoc, collection } from 'firebase/firestore';

// Main
export const AspirantForm = () => {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [contactNum, setContactNum] = useState("")
    const [interestedRole, setInterestedRole] = useState("")
    const [fileName, setFileName] = useState("")
    const [file, setFile] = useState("")

    const [prospectData, setProspectData] = useState({})
    const [loading, setLoading] = useState(false)
    const [submission, setSubmission] = useState()
    const [progress, setProgress] = useState(0);

    const aspirantsCollectionRef = collection(db, "aspirants")

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true)

        if (file) {
            const storageRef = ref(storage, `files/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const prog = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(prog);
                },
                (error) => console.log(error),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        const data = {
                            name,
                            email,
                            contactNum,
                            interestedRole,
                            resume: downloadURL
                        }

                        await addDoc(aspirantsCollectionRef, data)

                        setProspectData(data)
                        setLoading(false)
                        setSubmission("success")
                    });
                }
            );



            // axios.post(`${process.env.REACT_APP_SERVER_HOST}/aspirants/register`, data)
            //     .then(() => {
            //         setProspectData(data)
            //         setLoading(false)
            //         setSubmission("success")
            //     });


        } else {
            const data = {
                name,
                email,
                contactNum,
                interestedRole,
            }

            await addDoc(aspirantsCollectionRef, data)

            setProspectData(data)
            setLoading(false)
            setSubmission("success")

            // axios.post(`${process.env.REACT_APP_SERVER_HOST}/aspirants/register`, data)
            //     .then(() => {
            //         setProspectData(data)
            //         setLoading(false)
            //         setSubmission("success")
            //     });

        }
    }


    // const handleSubmit = (e) => {
    //     e.preventDefault()

    //     if (file) {
    //         const fileData = new FormData()
    //         fileData.append('file', file, file.name)

    //         axios.post(`${process.env.REACT_APP_SERVER_HOST}/aspirants/upload`, fileData, {
    //             headers: {
    //                 'Content-Type': `multipart/form-data; boundary=${fileData._boundary}`,
    //             }
    //         }).then((res) => {
    //             const data = {
    //                 name,
    //                 email,
    //                 contactNum,
    //                 interestedRole,
    //                 resume: res.data.filename
    //             }
    //             axios.post(`${process.env.REACT_APP_SERVER_HOST}/aspirants/register`, data)
    //                 .then(() => {
    //                     setProspectData(data)
    //                     setLoading(false)
    //                     setSubmission("success")
    //                 });
    //             console.log(data)

    //             setName("")
    //             setEmail("")
    //             setContactNum("")
    //             setInterestedRole("")

    //         })
    //     } else {
    //         const data = {
    //             name,
    //             email,
    //             contactNum,
    //             interestedRole,
    //         }
    //         axios.post(`${process.env.REACT_APP_SERVER_HOST}/aspirants/register`, data)
    //             .then(() => {
    //                 setProspectData(data)
    //                 setLoading(false)
    //                 setSubmission("success")
    //             });

    //         console.log(data)

    //         setName("")
    //         setEmail("")
    //         setContactNum("")
    //         setInterestedRole("")
    //     }
    // }


    return (
        <>
            <AspirantFormContainer>
                <Title>
                    Apply Online !
                </Title>
                <div style={{ margin: '40px 0' }} />
                <SpacerTopBottomLarge>
                    <AspirantFlexbox>
                        <AspirantLabel>Interested role:</AspirantLabel>
                        <AspirantInput
                            id="outlined"
                            type='text'
                            variant="outlined"
                            value={interestedRole}
                            onChange={(e) => setInterestedRole(e.target.value)}
                        />
                    </AspirantFlexbox>
                </SpacerTopBottomLarge>
                <SpacerTopBottomLarge>
                    <AspirantFlexbox>
                        <AspirantLabel>Name:</AspirantLabel>
                        <AspirantInput
                            id="outlined"
                            type='text'
                            variant="outlined"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </AspirantFlexbox>
                </SpacerTopBottomLarge>
                <SpacerTopBottomLarge>
                    <AspirantFlexbox>
                        <AspirantLabel>Contact number:</AspirantLabel>
                        <AspirantInput
                            id="outlined"
                            type='text'
                            variant="outlined"
                            value={contactNum}
                            onChange={(e) => setContactNum(e.target.value)}
                        />
                    </AspirantFlexbox>
                </SpacerTopBottomLarge>
                <SpacerTopBottomLarge>
                    <AspirantFlexbox>
                        <AspirantLabel>Email address:</AspirantLabel>
                        <AspirantInput
                            id="outlined"
                            type='text'
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </AspirantFlexbox>
                </SpacerTopBottomLarge>
                <SpacerTopBottomLarge>
                    <AspirantFlexboxResume>
                        <AspirantLabel>Resume:</AspirantLabel>
                        <FileUpload
                            onChange={e => setFile(e.target.files[0])}
                        >
                            Upload
                        </FileUpload>

                    </AspirantFlexboxResume>
                    <AlignCenter>
                        {
                            file.name
                            &&
                            <SelectedFileName>{file.name}</SelectedFileName>
                        }
                    </AlignCenter>
                </SpacerTopBottomLarge>
                {
                    loading ?
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                            fullWidth
                        >
                            Submit
                        </LoadingButton>
                        :
                        <Button
                            variant="contained"
                            fullWidth
                            style={{ backgroundColor: '#51B960' }}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                }
                {
                    submission === "success"
                    &&
                    <HomeCTASuccess>
                        Thanks for getting in touch {prospectData.name},
                        one of our HR representatives will connect with you shortly
                    </HomeCTASuccess>
                }
            </AspirantFormContainer>
        </>
    );
};