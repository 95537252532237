export const sizes = [
    "8px",
    "16px",
    "32px",
    "64px",
    "128px",
    "200px",
    "256px",
    "512px",
    "1024px"
];