import { Button, TextField } from '@mui/material';
import styled from 'styled-components'

export const OfficeAddressContainer = styled.div`
    padding-left: ${(props) => props.theme.space[18]};
    padding-right: ${(props) => props.theme.space[18]};
    padding-top: ${(props) => props.theme.space[5]};
    padding-bottom: ${(props) => props.theme.space[5]};
    background-color: ${(props) => props.theme.colors.ui.grey20};

    @media only screen and (min-width: 1800px) {
        padding-left: 248px;
        padding-right: 248px;
    }

    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }

    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }

    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }

    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
`;

export const MapContainer = styled.div`
    width: 100%;
    height: 400px;
`;

export const AspirantFormContainer = styled.div`
    padding: ${(props) => props.theme.space[4]};
    background-color: ${(props) => props.theme.colors.ui.grey40};
`;

export const AspirantLabel = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    margin-left: auto;
    margin-right: 16px;

    @media only screen and (max-width: 450px) {
        margin: 0 0 8px 0;
    }
`;

export const AspirantInput = styled(TextField)`
    background-color: white;
    width: 250px;
    border-radius: 4px;
`;

export const RolesAvailable = styled.div`
    padding: ${(props) => props.theme.space[3]};
    background-color: ${(props) => props.theme.colors.ui.grey20};
    margin-right: 24px;
    min-height: 100px; 
`;

export const ContactCTAButton = styled(Button)`
    &.customStyle {
        background-color: ${(props) => props.theme.colors.primary.yellow};
        font-size: ${(props) => props.theme.fontSizes.subHeading};
        color: ${(props) => props.theme.colors.ui.grey80};
        font-weight: ${(props) => props.theme.fontWeights.bold};
        text-transform: none;
        border-radius: ${(props) => props.theme.sizes[1]};

        &:hover {
            background-color: ${(props) => props.theme.colors.primary.green};
        }

        @media only screen and (min-width: 1800px) {
            font-size: ${(props) => props.theme.fontSizes.subCaption};
        }

        @media only screen and (max-width: 1300px) {
            font-size: ${(props) => props.theme.fontSizes.title};
        }

        @media only screen and (max-width: 850px) {
            margin-top: 16px;
    }
    } 
`;

export const ContactSubHeading = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.text.grey80};

    @media only screen and (max-width: 1300px) {
        font-size: 28px;
    }

    @media only screen and (max-width: 1100px) {
        font-size: ${(props) => props.theme.fontSizes.title};
    }

    @media only screen and (max-width: 850px) {
        font-size: 20px;
        text-align: center;
    }
`;

export const ContactFlexboxSE = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media only screen and (max-width: 850px) {
        flex-direction: column;
    }
`;

export const ContactSocialIcons = styled.div`
    
    @media only screen and (max-width: 850px) {
        margin: 24px 0;
    }
`;

export const ContactHRManager = styled.div`
    
    @media only screen and (max-width: 850px) {
        margin: 24px 0;
    }
`;

export const AspirantFlexbox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 450px) {
        flex-direction: column;
    }
`;

export const AspirantFlexboxResume = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 450px) {
        flex-direction: column;
        margin-right: 0;
    }
`;

export const CenterAbout = styled.div`

    @media only screen and (max-width: 850px) {
        margin-top: 32px;
    }
`;
