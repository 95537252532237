import styled from 'styled-components'
import { Button, Grid, TextField } from '@mui/material';
import testimonialImg from '../../../assets/images/testimonialBg.png'
import mobileTestimonialImg from '../../../assets/images/mobileRed.png'
import heroImg from '../../../assets/images/heroImg.jpg'
import heroImgMd from '../../../assets/images/heroImgMd.jpg'
import mobileHeroImage from '../../../assets/images/mobileHeroImage.jpg'

export const HeroImageContainer = styled.div`
    position: relative;
    height: 100vh;
    background-image: url(${heroImg});
    background-size: cover;
    background-repeat: no-repeat;
    
    @media only screen and (max-width: 1100px) {
        background-image: url(${heroImgMd}); 
    }

    @media only screen and (max-width: 850px) {
        background-image: url(${mobileHeroImage}); 
    }

    @media only screen and (max-width: 650px) {
        
    }

    @media only screen and (max-width: 350px) {
        
    }
`;

export const HeroImage = styled.img`
    width: 100%;
    position: relative;
    z-index: -1;
`;

export const Layer = styled.div`
    background-color: #EAEAEA;
    opacity: 0.8;
    position: absolute;
    width: 92%;
    height: 92%;
    left: 4%;
    top: 4%;

    @media only screen and (max-width: 1100px) {
        height: 88%;
    }

    @media only screen and (max-width: 850px) {
        height: 92%;
    }
`;

export const AboveCenterNav = styled.div`
    position: absolute;
    width: 100%;
    z-index: 2;
`;

export const AboveCenter = styled.div`
    position: absolute;
    width: 100%;
    z-index: 1;
    text-align: center;
    top: 25%;

    @media only screen and (max-width: 1100px) {
        top: 30%;
    }

    @media only screen and (max-width: 650px) {
        top: 25%;
    }
`;

export const MainCTAButton = styled(Button)`
    &.customStyle {
        background-color: ${(props) => props.theme.colors.primary.yellow};
        font-size: ${(props) => props.theme.fontSizes.subHeading};
        color: ${(props) => props.theme.colors.ui.grey80};
        font-weight: ${(props) => props.theme.fontWeights.bold};
        text-transform: none;
        border-radius: ${(props) => props.theme.sizes[1]};

        &:hover {
            background-color: ${(props) => props.theme.colors.primary.green};
        }

        @media only screen and (min-width: 1800px) {
            font-size: ${(props) => props.theme.fontSizes.subCaption};
        }

        @media only screen and (max-width: 1135px) {
            font-size: ${(props) => props.theme.fontSizes.title};
        }

        @media only screen and (max-width: 650px) {
            font-size: ${(props) => props.theme.fontSizes.subHeading};
            margin-top: 250px;
        }

        @media only screen and (max-width: 450px) {
            font-size: ${(props) => props.theme.fontSizes.subHeading};
            margin-top: 180px;
        }

        @media only screen and (max-width: 350px) {
            font-size: ${(props) => props.theme.fontSizes.title};
            margin-top: 100px;
        }
    } 
`;

export const CapabilityCardCover = styled.div`
    padding-top: ${(props) => props.theme.space[2]};
    padding-right: ${(props) => props.theme.space[3]};
    padding-bottom: ${(props) => props.theme.space[2]};
    padding-left: ${(props) => props.theme.space[3]};
    background-color: ${(props) => props.theme.colors.primary.green20};
    border-top-right-radius: ${(props) => props.theme.sizes[1]};
    border-top-left-radius: ${(props) => props.theme.sizes[1]};
    width: 176px;
    text-align: center;
`;

export const CapabilityCardScroll = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const CapabilitySqueeze = styled.div`
    padding-left: ${(props) => props.theme.space[18]};
    padding-right: ${(props) => props.theme.space[18]};

    @media only screen and (min-width: 1800px) {
        padding-left: 248px;
        padding-right: 248px;
    }

    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }

    @media only screen and (max-width: 1100px) {
        padding-left: 0;
        padding-right: 0;
    }

    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
`;

export const CCContainer = styled.div`
    position: relative;
    margin-top: 88px;
    cursor: pointer;
    &:hover {
        transform: scale(1.03);
        transition: all 0.5s ease;
   }
   &:not(:hover) {
    transform: scale(1);
        transition: all 0.5s ease;
   }
`;

export const CCBottom = styled.div`
    align-self: flex-end;
    margin: 0 auto;
`;

export const CapabilityImageContainer = styled.div`
    position: absolute;
    width: 90%;
    height: 176px;
    left: 50%;
    bottom: 40%;
    transform: translate(-50%, 0);
    border-radius: 16px;
`;

export const CapabilityImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 16px;
`;

export const ColumnFlex = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding: ${(props) => props.theme.space[3]};
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
`;

export const BlockLeft = styled.div`
    width: 100%;
    height: 40px;
`;

export const BlockRight = styled.div`
    width: 100%;
    height: 70px;
    border-top-right-radius: 16px;
`;

export const ExpertiseCardCover = styled.div`
    box-shadow: 0px 0px 3px 1px rgba(180,180,180,0.75);
    -webkit-box-shadow: 0px 0px 3px 1px rgba(180,180,180,0.75);
    -moz-box-shadow: 0px 0px 3px 1px rgba(180,180,180,0.75);
    cursor: pointer;
   &:hover {
        transform: scale(1.03);
        transition: all 0.5s ease;
   }
   &:not(:hover) {
    transform: scale(1);
        transition: all 0.5s ease;
   }
`;

export const CardInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-left: ${(props) => props.theme.space[4]};
    padding-right: ${(props) => props.theme.space[4]};
    padding-top: ${(props) => props.theme.space[3]};
    padding-bottom: ${(props) => props.theme.space[3]};
`;

export const ExpertiseCardImage = styled.img`
    width: 100%;
    height: 100%;
`;

export const TestimonialSection = styled.div`
    background-image: url(${testimonialImg});
    background-size: 100%;

    @media only screen and (max-width: 650px) {
        background-image: url(${mobileTestimonialImg});
    }
`;

export const TestimonialContainer = styled.div`
    padding: ${(props) => props.theme.space[6]};
    border-radius: 24px;

    @media only screen and (max-width: 850px) {
        padding: ${(props) => props.theme.space[4]};
    }
`;

export const TestimonialGridLeft = styled(Grid)`
    position: relative;
    display: flex;

    @media only screen and (max-width: 850px) {
        display: block;
        padding-top: 128px;
    }

    @media only screen and (max-width: 650px) {
        display: block;
        padding-top: 104px;
    }
`;

export const TestimonialGridRight = styled(Grid)`

`;

export const TestimonialCircle = styled.div`
    border-radius: 50%;
    width: ${(props) => props.theme.sizes[5]};
    height: ${(props) => props.theme.sizes[5]};
    align-self: flex-end;
    margin: 0 auto;

    @media only screen and (max-width: 650px) {
        width: 160px;
        height: 160px;
    }

    @media only screen and (max-width: 450px) {
        width: ${(props) => props.theme.sizes[4]};
        height: ${(props) => props.theme.sizes[4]};
    }
`;

export const TestimonialUserImage = styled.img`
    position: absolute;
    width: ${(props) => props.theme.sizes[4]};
    left: 50%;
    transform: translate(-50%, 0);
    bottom: ${(props) => props.theme.space[5]};

    @media only screen and (max-width: 650px) {
        width: 100px;
    }

    @media only screen and (max-width: 450px) {
        width: 80px;
    }
`;

export const TestimonialQuoteSection = styled.div`
    background-color: ${(props) => props.theme.colors.secondary.red};
    padding: ${(props) => props.theme.space[3]};
    border-radius: 24px;
    min-height: 160px;
`;

export const CtaLabel = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    margin-left: auto;
    margin-right: 16px;

    @media only screen and (min-width: 1800px) {
        margin-left: 15%;
        margin-right: 0;
    }

    @media only screen and (max-width: 450px) {
        font-size: 20px;
        margin-left: 0;
        margin-right: 0;
    }

    @media only screen and (max-width: 380px) {
        font-size: 18px;
    }
`;

export const CtaInput = styled(TextField)`
    background-color: white;
    width: 320px;

    @media only screen and (min-width: 1800px) {
        width: 400px;
    }

    @media only screen and (max-width: 450px) {
        width: 200px;
    }

    @media only screen and (max-width: 380px) {
        width: 150px;
    }
`;

export const LogoHome = styled.div`
    width: 250px;
    margin-right: ${(props) => props.theme.space[15]};
    margin-left: ${(props) => props.theme.space[15]};
`;

export const SubHeadingAddress = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    font-weight: ${(props) => props.theme.fontWeights.bold};

    @media only screen and (max-width: 650px) {
        font-size: 24px;
    }
`;

export const FlexboxHomeCTA = styled.div`
    display: flex;
    align-items: center;

    @media only screen and (min-width: 1800px) {
        justify-content: space-between;
    }

    @media only screen and (max-width: 450px) {
        justify-content: space-between;
    }
`;

export const HomeCTASuccess = styled.div`

    padding: ${(props) => props.theme.space[2]};
    border: 3px solid ${(props) => props.theme.colors.primary.green};
    border-radius: 16px;
    color: ${(props) => props.theme.colors.accents.indigo};
    margin-top: 24px;
    background-color: ${(props) => props.theme.colors.ui.white};
    font-weight: ${(props) => props.theme.fontWeights.bold};

    @media only screen and (min-width: 1800px) {
        
    }

    @media only screen and (max-width: 450px) {
        
    }
`;

export const SelectedFileName = styled.div`
    padding: ${(props) => props.theme.space[2]};
    border: 3px solid ${(props) => props.theme.colors.accents.indigo};
    border-radius: 8px;
    color: ${(props) => props.theme.colors.accents.orange};
    margin-top: 8px;
    background-color: ${(props) => props.theme.colors.ui.white};
    font-weight: ${(props) => props.theme.fontWeights.bold};

    @media only screen and (min-width: 1800px) {
        
    }

    @media only screen and (max-width: 450px) {
        
    }
`;



