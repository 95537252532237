import React from 'react'
import './fileUpload.css'

export const FileUpload = ({children, ...props}) => {
    return (
        <label className='upload-btn-sx'>
            <input type='file' style={{display:'none'}} {...props}/>
            {children}
        </label>
    )
}