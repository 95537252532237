import styled from "styled-components";

// ----------------------------------------Small----------------------------------------//


export const SpacerTopSmall = styled.div`
    margin-top: ${(props) => props.theme.space[1]};
`;

export const SpacerBottomSmall = styled.div`
    margin-bottom: ${(props) => props.theme.space[1]};
`;

export const SpacerLeftSmall = styled.div`
    margin-left: ${(props) => props.theme.space[1]};
`;

export const SpacerRightSmall = styled.div`
    margin-right: ${(props) => props.theme.space[1]};
`;

export const SpacerTopBottomSmall = styled.div`
    margin-top: ${(props) => props.theme.space[1]};
    margin-bottom: ${(props) => props.theme.space[1]};
`;

export const SpacerLeftRightSmall = styled.div`
    margin-left: ${(props) => props.theme.space[1]};
    margin-right: ${(props) => props.theme.space[1]};
`;

// ----------------------------------------Medium----------------------------------------//

export const SpacerTopMedium = styled.div`
    margin-top: ${(props) => props.theme.space[2]};
`;

export const SpacerBottomMedium = styled.div`
    margin-bottom: ${(props) => props.theme.space[2]};
`;

export const SpacerLeftMedium = styled.div`
    margin-left: ${(props) => props.theme.space[2]};
`;

export const SpacerRightMedium = styled.div`
    margin-right: ${(props) => props.theme.space[2]};
`;

export const SpacerTopBottomMedium = styled.div`
    margin-top: ${(props) => props.theme.space[2]};
    margin-bottom: ${(props) => props.theme.space[2]};
`;

export const SpacerLeftRightMedium = styled.div`
    margin-left: ${(props) => props.theme.space[2]};
    margin-right: ${(props) => props.theme.space[2]};
`;

// ----------------------------------------Large----------------------------------------//

export const SpacerTopLarge = styled.div`
    margin-top: ${(props) => props.theme.space[3]};
`;

export const SpacerBottomLarge = styled.div`
    margin-bottom: ${(props) => props.theme.space[3]};
`;

export const SpacerLeftLarge = styled.div`
    margin-left: ${(props) => props.theme.space[3]};
`;

export const SpacerRightLarge = styled.div`
    margin-right: ${(props) => props.theme.space[3]};
`;

export const SpacerTopBottomLarge = styled.div`
    margin-top: ${(props) => props.theme.space[3]};
    margin-bottom: ${(props) => props.theme.space[3]};
`;

export const SpacerLeftRightLarge = styled.div`
    margin-left: ${(props) => props.theme.space[3]};
    margin-right: ${(props) => props.theme.space[3]};
`;

// ----------------------------------------XL----------------------------------------//

export const SpacerTopXL = styled.div`
    margin-top: ${(props) => props.theme.space[4]};
`;

export const SpacerBottomXL = styled.div`
    margin-bottom: ${(props) => props.theme.space[4]};
`;

export const SpacerLeftXL = styled.div`
    margin-left: ${(props) => props.theme.space[4]};
`;

export const SpacerRightXL = styled.div`
    margin-right: ${(props) => props.theme.space[4]};
`;

export const SpacerTopBottomXL = styled.div`
    margin-top: ${(props) => props.theme.space[4]};
    margin-bottom: ${(props) => props.theme.space[4]};
`;

export const SpacerLeftRightXL = styled.div`
    margin-left: ${(props) => props.theme.space[4]};
    margin-right: ${(props) => props.theme.space[4]};
`;

// ----------------------------------------XXL----------------------------------------//

export const SpacerTopXXL = styled.div`
    margin-top: ${(props) => props.theme.space[5]};
`;

export const SpacerBottomXXL = styled.div`
    margin-bottom: ${(props) => props.theme.space[5]};
`;

export const SpacerLeftXXL = styled.div`
    margin-left: ${(props) => props.theme.space[5]};
`;

export const SpacerRightXXL = styled.div`
    margin-right: ${(props) => props.theme.space[5]};
`;

export const SpacerTopBottomXXL = styled.div`
    margin-top: ${(props) => props.theme.space[5]};
    margin-bottom: ${(props) => props.theme.space[5]};
`;

export const SpacerLeftRightXXL = styled.div`
    margin-left: ${(props) => props.theme.space[5]};
    margin-right: ${(props) => props.theme.space[5]};
`;

// ----------------------------------------XXXL----------------------------------------//

export const SpacerTopXXXL = styled.div`
    margin-top: ${(props) => props.theme.space[6]};
`;

export const SpacerBottomXXXL = styled.div`
    margin-bottom: ${(props) => props.theme.space[6]};
`;

export const SpacerLeftXXXL = styled.div`
    margin-left: ${(props) => props.theme.space[6]};
`;

export const SpacerRightXXXL = styled.div`
    margin-right: ${(props) => props.theme.space[6]};
`;

export const SpacerTopBottomXXXL = styled.div`
    margin-top: ${(props) => props.theme.space[6]};
    margin-bottom: ${(props) => props.theme.space[6]};
`;

export const SpacerLeftRightXXXL = styled.div`
    margin-left: ${(props) => props.theme.space[6]};
    margin-right: ${(props) => props.theme.space[6]};
`;

// ----------------------------------------XXXXL----------------------------------------//

export const SpacerTopXXXXL = styled.div`
    margin-top: ${(props) => props.theme.space[7]};
`;

export const SpacerBottomXXXXL = styled.div`
    margin-bottom: ${(props) => props.theme.space[7]};
`;

export const SpacerLeftXXXXL = styled.div`
    margin-left: ${(props) => props.theme.space[7]};
`;

export const SpacerRightXXXXL = styled.div`
    margin-right: ${(props) => props.theme.space[7]};
`;

export const SpacerTopBottomXXXXL = styled.div`
    margin-top: ${(props) => props.theme.space[7]};
    margin-bottom: ${(props) => props.theme.space[7]};
`;

export const SpacerLeftRightXXXXL = styled.div`
    margin-left: ${(props) => props.theme.space[7]};
    margin-right: ${(props) => props.theme.space[7]};

    @media only screen and (max-width: 1135px) {
        margin-left: ${(props) => props.theme.space[5]};
        margin-right: ${(props) => props.theme.space[5]};
    }

    @media only screen and (max-width: 950px) {
        margin-left: ${(props) => props.theme.space[4]};
        margin-right: ${(props) => props.theme.space[4]};
    }
`;
