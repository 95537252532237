import styled from 'styled-components'

export const SearchInputContainer = styled.div`
    display: flex;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    margin-left: 300px;
    margin-right: 300px;

    @media only screen and (max-width: 1300px) {
        margin-left: 150px;
        margin-right: 150px;
    }

    @media only screen and (max-width: 850px) {
        margin-left: 100px;
        margin-right: 100px;
    }

    @media only screen and (max-width: 650px) {
        margin: 0 auto;
    }
`;

export const SearchInput = styled.input`
    background-color: white;
    border: 0;
    border-radius: 5px;
    font-size: 18px;
    padding: 15px;
    height: 100%;
    width: 100%;
    &:focus {
        outline: none;
    }
`;

export const SearchIcons = styled.div`
    padding: 10px;
    background-color: white;
    display: grid;
    place-items: center;
    border-radius: 5px;
`;

export const ProjectContainer = styled.div`
    width: 100%;
    height: 450px;
    background-color: ${(props) => props.theme.colors.ui.grey60};
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    box-shadow: 0px 0px 3px 1px rgba(180,180,180,0.75);
    -webkit-box-shadow: 0px 0px 3px 1px rgba(180,180,180,0.75);
    -moz-box-shadow: 0px 0px 3px 1px rgba(180,180,180,0.75);
    cursor: pointer;
   &:hover {
        transform: scale(1.03);
        transition: all 0.5s ease;
   }
   &:not(:hover) {
    transform: scale(1);
        transition: all 0.5s ease;
   }
`;