// Defaults
import React from 'react';
import { FormBackground, FormExit, SuccessHeading } from '../../global.styles';

// Ext-libs
import Lottie from 'react-lottie';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

// Int-libs
import successAnimation from '../../../assets/animations/success-animation.json'
import { useNavigate } from 'react-router-dom';

// Main
export const CtaFormSuccess = (props) => {

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: successAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const navigate = useNavigate()

    return (
        <>
            <FormBackground>
                <SuccessHeading>
                    Thanks {props.name}! one of our representatives will connect with you shortly
                </SuccessHeading>
                <Lottie
                    options={defaultOptions}
                    width={'300px'}
                    height={'300px'}
                />
                <FormExit onClick={() => { navigate("/") }}>
                    Return to home
                </FormExit>
            </FormBackground>
        </>
    );
};