// Defaults
import React from 'react';


// Ext-libs
import { Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

// Int-libs
import CircularPH from '../../assets/images/CircularPH.png'
import { CardInfo } from '../home/components/home.styles';
import { PeopleBlock } from './components/peopleBlock';
import People1 from '../../assets/images/people1.png'
import People2 from '../../assets/images/people2.png'
import People3 from '../../assets/images/people3.png'
import People4 from '../../assets/images/people4.png'
import People5 from '../../assets/images/people5.png'
import { Footer } from '../../global/components/footer/footer';
import ScrollToTop from '../../global/components/scrollToTop/scrollToTop';
import { SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXL, SpacerTopBottomXXL, SpacerTopBottomXXXL } from '../../design/spacer/spacer';
import { Navbar } from '../../global/components/navbar/navbar';
import { AlignCenter, Body, BodyJLH2, BodyJLHmedium, BodyLH2, Bottom, Center, Container, ContainerGrey, FlexboxSB, FlexColumnSB, HeadingContainer, Left, PageStart, Right, Sandwich, SubCaption, SubHeading, Title, Top } from '../../global/global.styles';
import { AchievementGrid, AchievementInfo, AchievementStatBlock, BodyGrid, LogoCover, LogoGrid } from './components/about.styles';
import useWindowDimensions from '../../global/components/screenSize/screenSize';
import Logo from '../../assets/images/SriSai512x512.png'

// Main
export const About = () => {

    const location = useLocation();

    const { height, width } = useWindowDimensions();

    return (
        <>
            <ScrollToTop />
            <Navbar
                currentPage={location.pathname}
            />
            <PageStart />
            <HeadingContainer>
                <SubCaption>Sri Sai Engineers</SubCaption>
                <SpacerTopBottomXL />
                <Grid container>
                    <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
                        <BodyJLH2>
                            Constructing a building has a reputation of being a tedious job, where one has to be satisfied with what the builder builds rather than the other way around. People often struggle to find the right company which satisfies not only their budget but also bring to life what they envision.
                        </BodyJLH2>
                        <SpacerTopBottomLarge />
                        <BodyJLH2>
                            We at Sri Sai Engineers have ventured into the field of construction, maintenance, landscaping and housekeeping to build eco-friendly and smart homes that yield your happiness throughout. We are one step ahead not just by constructing buildings but also understand your emotions and the hard work you would be investing into accomplishing your dreams.
                        </BodyJLH2>
                        <SpacerTopBottomLarge />
                        <BodyJLH2>
                            Our aim is to develop trust and long-term relationships with customers. Depending on your affordability, we suggest plans and support you throughout in fulfilling your dreams.
                        </BodyJLH2>
                        <SpacerTopBottomLarge />
                        <BodyJLH2>
                            The buildings that we construct have resistance to weather and ensure they stand strong to shelter many generations to come. The material that we use are absolutely eco-friendly and go in line with green building norms to create pleasantness at all time. We carry out renovation and repairs to building and other structures to ensure a stress-free atmosphere. Our aim is to develop trust and long-term relationships with customers.
                        </BodyJLH2>
                        <SpacerTopBottomLarge />
                        <BodyJLH2>
                            We are also into the field of development of smart farm lands and maintaining the same. Starting with construction of farmhouse to farming the lands, with value adds such as smart monitoring and security.
                        </BodyJLH2>
                        <SpacerTopBottomLarge />
                        <BodyJLH2>
                            We provide architectural and structural consultancy, interior designing, legal consultancy, plan sanction and other government approvals for private and government sectors.
                        </BodyJLH2>
                    </Grid>
                    <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
                        <FlexColumnSB>
                            <Top>
                                <LogoCover>
                                    <img src={Logo} alt="image" style={{ width: '100%' }} />
                                </LogoCover>
                            </Top>
                            <Bottom>
                                {/* <AchievementInfo>
                                    <Top>
                                        <FlexboxSB>
                                            <Left>
                                                <AchievementStatBlock>
                                                    <SubHeading>
                                                        24 +
                                                    </SubHeading>
                                                    <SpacerTopBottomMedium />
                                                    <Title>
                                                        Achievement
                                                    </Title>
                                                </AchievementStatBlock>
                                            </Left>
                                            <Right>
                                                <AchievementStatBlock>
                                                    <SubHeading>
                                                        24 +
                                                    </SubHeading>
                                                    <SpacerTopBottomMedium />
                                                    <Title>
                                                        Achievement
                                                    </Title>
                                                </AchievementStatBlock>
                                            </Right>
                                        </FlexboxSB>
                                    </Top>
                                    <SpacerTopBottomLarge />
                                    <Bottom>
                                        <FlexboxSB>
                                            <Left>
                                                <AchievementStatBlock>
                                                    <SubHeading>
                                                        24 +
                                                    </SubHeading>
                                                    <SpacerTopBottomMedium />
                                                    <Title>
                                                        Achievement
                                                    </Title>
                                                </AchievementStatBlock>
                                            </Left>
                                            <Right>
                                                <AchievementStatBlock>
                                                    <SubHeading>
                                                        24 +
                                                    </SubHeading>
                                                    <SpacerTopBottomMedium />
                                                    <Title>
                                                        Achievement
                                                    </Title>
                                                </AchievementStatBlock>
                                            </Right>
                                        </FlexboxSB>
                                    </Bottom>
                                </AchievementInfo> */}
                            </Bottom>
                        </FlexColumnSB>
                    </Grid>
                </Grid>
            </HeadingContainer>
            <Sandwich />
            {/* <Container>
                <SubHeading>
                    Meet Our People
                </SubHeading>
            </Container>
            <ContainerGrey>
                <Grid container>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <PeopleBlock
                            image={People1}
                            name={"Jenny Thomson"}
                            designation={"Managing Director & Chief Digital Officer"}
                        />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <PeopleBlock
                            image={People2}
                            name={"Jenny Thomson"}
                            designation={"Managing Director & Chief Digital Officer"}
                        />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <PeopleBlock
                            image={People3}
                            name={"Jenny Thomson"}
                            designation={"Managing Director & Chief Digital Officer"}
                        />
                    </Grid>
                </Grid>
                {
                    width > 850
                    &&
                    <SpacerTopBottomXXXL />
                }
                <Grid container>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <PeopleBlock
                            image={People4}
                            name={"Jenny Thomson"}
                            designation={"Managing Director & Chief Digital Officer"}
                        />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <PeopleBlock
                            image={People5}
                            name={"Jenny Thomson"}
                            designation={"Managing Director & Chief Digital Officer"}
                        />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>

                    </Grid>
                </Grid>
            </ContainerGrey> */}
            <Footer />
        </>
    );
};