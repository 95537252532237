import React from 'react';
import ReactDOM from 'react-dom';

import { theme } from './design/theme';
import App from './App';

import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from 'styled-components';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root')
);

