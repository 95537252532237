// Defaults
import React, { useEffect, useState } from 'react';
import { Navbar } from '../../global/components/navbar/navbar';
import { ProjectContainer, SearchIcons, SearchInput, SearchInputContainer, SearchInputs } from './components/projects.styles';

// Ext-libs
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { AlignCenter, Body, BodyWhiteSB, Container, Heading, HeadingItalicsWhite, HeadingItalicsWhiteR, HeadingWhite, HeadingWhiteSB, PageStart, Sandwich, Squeeze, SubHeading, SubTitleRegular, Title, TitleWhite } from '../../global/global.styles';
import { SpacerBottomLarge, SpacerBottomXL, SpacerBottomXXL, SpacerBottomXXXL, SpacerTopBottomLarge } from '../../design/spacer/spacer';
import { ProjectTile } from './components/projectTile';
import { Footer } from '../../global/components/footer/footer';
import axios from 'axios';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import ScrollToTop from '../../global/components/scrollToTop/scrollToTop';
import { Box } from '@mui/system';
import { LinearProgress } from '@mui/material';
import { db } from '../../database/firebase';
import { collection, getDocs } from 'firebase/firestore';

// Int-libs


// Main
export const Projects = () => {

    const navigate = useNavigate()

    const location = useLocation();

    const [projects, setProjects] = useState()

    const projectsCollectionRef = collection(db, "projects")

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        // const data = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/projects/fetchAll`)
        //     .then(setLoading(false))
        // setProjects(data.data)

        const getProjects = async () => {
            const data = await getDocs(projectsCollectionRef);
            setProjects(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getProjects();
        setLoading(false);
    }, [])

    return (
        <>
            <ScrollToTop />
            <Navbar
                currentPage={location.pathname}
            />
            <PageStart />
            <Squeeze>
                <SearchInputContainer>
                    <SearchInput
                        type="text"
                        placeholder='Search for projects'
                    />
                    <SearchIcons>
                        <SearchRoundedIcon />
                    </SearchIcons>
                </SearchInputContainer>
            </Squeeze>
            <Container>
                {
                    loading ?
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                        :
                        projects && Object.keys(projects?.length) > 0
                            ?
                            projects?.map((item) => (
                                <SpacerBottomXXL>
                                    <ProjectTile
                                        title={item?.title}
                                        subTitle={item?.subTitle}
                                        description={item?.description}
                                        click={() => {
                                            navigate(`/projects/${item?.id}`)
                                        }}
                                    />
                                </SpacerBottomXXL>
                            ))
                            :
                            <Sandwich>
                                <SubTitleRegular style={{ textAlign: 'center' }}>
                                    Coming soon !
                                </SubTitleRegular>
                            </Sandwich>
                }
            </Container>
            <Footer />
        </>
    );
};