// Defaults
import React from 'react';
import '../style/home.css'

// Ext-libs
import { useNavigate } from "react-router-dom";

// Int-libs
import {
    SpacerBottomXXL,
    SpacerBottomXXXL,
    SpacerLeftRightLarge,
    SpacerTopBottomLarge,
    SpacerTopBottomXXL,
    SpacerTopBottomXXXL,
    SpacerTopXXL
} from '../../../design/spacer/spacer';
import modernHomes from '../../../assets/images/modernHome.jpg'
import commercialBuildings from '../../../assets/images/commercial.jpg'
import farmhouseVilla from '../../../assets/images/farmhouse.jpg'
import infrastructure from '../../../assets/images/infrastructure.jpg'
import smartHomes from '../../../assets/images/smartHomes.jpg'
import constructionImg from '../../../assets/images/construction.jpg'
import consultingImg from '../../../assets/images/consulting.jpg'
import maintenanceImg from '../../../assets/images/maintenance.jpg'
import salesImg from '../../../assets/images/sales.jpg'
import supervisionImg from '../../../assets/images/supervision.jpg'
import useWindowDimensions from '../../../global/components/screenSize/screenSize';
import { AlignCenter, Container, Heading, Sandwich, Squeeze, SubHeading } from '../../../global/global.styles';
import { CapabilityCard } from './capabilityCard';
import { ExpertiseCardCenter, ExpertiseCardLeft, ExpertiseCardRight } from './expertiseCard';
import { CapabilityCardScroll } from './home.styles';

export const ExpertiseCards = () => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/projects')
    }

    const { height, width } = useWindowDimensions();

    return (
        <Sandwich>
            {
                width > 850
                    ?
                    <>
                        <ExpertiseCardLeft
                            image={modernHomes}
                            alt={"Modern Home"}
                            heading={"Modern Homes"}
                            description={"We prioritize safety, comfort and modern design. Our team of experts study industry trends to identify and incorporate the best in class design and functionality to bring to life your dream dwelling. Our staff value customer satisfaction and urge customer approvals and feedbacks at every step of this journey because we understand the importance of what a household means to you."}
                            click={handleClick}
                        />
                        <SpacerTopBottomXXXL />
                        <ExpertiseCardRight
                            image={smartHomes}
                            alt={"Smart Homes"}
                            heading={"Smart Homes"}
                            description={"We bring innovative controls into your home lifestyle to enrich everyday productivity and comfort with the beautiful décor of a contemporary home. Technology has facilitated the power of wireless and remote access for controlling various elements of a home such as electronics, heating/cooling systems, lighting, security etc., everything hidden behind the beautiful details of our expert designs."}
                            click={handleClick}
                        />
                        <SpacerTopBottomXXXL />
                        <ExpertiseCardLeft
                            image={commercialBuildings}
                            alt={"Commercial Building"}
                            heading={"Commercial Building"}
                            description={"Built to endure the test of time and any weather conditions, made out of industry grade material such as JSW/TATA steel and Ultratech/ Birla super cements promising safety and quality. Benefits of custom designs including architectural designs, structural designs, 3D visualization, MEP layouts as well as a detailed elements furniture plans."}
                            click={handleClick}
                        />
                        <SpacerTopBottomXXXL />
                        <ExpertiseCardRight
                            image={farmhouseVilla}
                            alt={"Farmhouse Villa"}
                            heading={"Farmhouse Villa"}
                            description={"Structures that complement environment by balancing ecology and synchronizing with other living beings to create a perfect ambiance to rejuvenate oneself. We promote the idea of  Improved health, better environment for future generation, and contribution towards reducing global warming and carbon emission."}
                            click={handleClick}
                        />
                        <SpacerTopBottomXXXL />
                        <ExpertiseCardLeft
                            image={infrastructure}
                            alt={"Infrastructure"}
                            heading={"Infrastructure"}
                            description={"Infrastructure is the physical and economic foundation of a nation and is a daily necessity to the vast populace of India, we therefore strive to develop world class structures with safety being the utmost priority. We undertake roadways, bridges, flyover, airports, telecommunications, power and energy, railways, water, waste management system, recreational facilities etc."}
                            click={handleClick}
                        />
                    </>
                    :
                    <>
                        <SpacerBottomXXL>
                            <ExpertiseCardCenter
                                image={modernHomes}
                                alt={"Modern Home"}
                                heading={"Modern Homes"}
                                description={"We prioritize safety, comfort and modern design. Our team of experts study industry trends to identify and incorporate the best in class design and functionality to bring to life your dream dwelling. Our staff value customer satisfaction and urge customer approvals and feedbacks at every step of this journey because we understand the importance of what a household means to you."}
                                click={handleClick}
                            />
                        </SpacerBottomXXL>
                        <SpacerTopBottomXXL>
                            <ExpertiseCardCenter
                                image={smartHomes}
                                alt={"Smart Homes"}
                                heading={"Smart Homes"}
                                description={"We bring innovative controls into your home lifestyle to enrich everyday productivity and comfort with the beautiful décor of a contemporary home. Technology has facilitated the power of wireless and remote access for controlling various elements of a home such as electronics, heating/cooling systems, lighting, security etc., everything hidden behind the beautiful details of our expert designs."}
                                click={handleClick}
                            />
                        </SpacerTopBottomXXL>
                        <SpacerTopBottomXXL>
                            <ExpertiseCardCenter
                                image={commercialBuildings}
                                alt={"Commercial Building"}
                                heading={"Commercial Building"}
                                description={"Built to endure the test of time and any weather conditions, made out of industry grade material such as JSW/TATA steel and Ultratech/ Birla super cements promising safety and quality. Benefits of custom designs including architectural designs, structural designs, 3D visualization, MEP layouts as well as a detailed elements furniture plans."}
                                click={handleClick}
                            />
                        </SpacerTopBottomXXL>
                        <SpacerTopBottomXXL>
                            <ExpertiseCardCenter
                                image={farmhouseVilla}
                                alt={"Farmhouse Villa"}
                                heading={"Farmhouse Villa"}
                                description={"Structures that complement environment by balancing ecology and synchronizing with other living beings to create a perfect ambiance to rejuvenate oneself. We promote the idea of  Improved health, better environment for future generation, and contribution towards reducing global warming and carbon emission."}
                                click={handleClick}
                            />
                        </SpacerTopBottomXXL>
                        <SpacerTopXXL>
                            <ExpertiseCardCenter
                                image={infrastructure}
                                alt={"Infrastructure"}
                                heading={"Infrastructure"}
                                description={"Infrastructure is the physical and economic foundation of a nation and is a daily necessity to the vast populace of India, we therefore strive to develop world class structures with safety being the utmost priority. We undertake roadways, bridges, flyover, airports, telecommunications, power and energy, railways, water, waste management system, recreational facilities etc."}
                                click={handleClick}
                            />
                        </SpacerTopXXL>
                    </>
            }
        </Sandwich>
    )
}
