// Defaults
import React, { useEffect, useState } from 'react';

// Ext-libs
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Grid } from '@mui/material';
import { collection, doc, getDoc } from "firebase/firestore";

// Int-libs
import { SpacerBottomMedium, SpacerBottomSmall, SpacerTopBottomLarge, SpacerTopBottomXL, SpacerTopBottomXXL, SpacerTopBottomXXXL, SpacerTopLarge } from '../../design/spacer/spacer';
import { Footer } from '../../global/components/footer/footer';
import { Navbar } from '../../global/components/navbar/navbar';
import { BodyLH2, Center, Container, FlexboxSB, FlexColumnSB, Heading, HeadingItalicsSB, HeadingSB, PageStart, Sandwich, SandwichL, SandwichXL, Squeeze, SubTitle, Title } from '../../global/global.styles';
import { ProjectContainer } from '../projects/components/projects.styles';
import { LaurelText, LaurelImage, LaurelImageContainer, MainImage, MainImageContainer, QuaternaryImage, QuaternaryImageContainer, SecondaryImage, SecondaryImageContainer, SecondaryImageContainerInv, SecondaryImageInv, TeritaryImage, TeritaryImageContainer, TeritaryImageContainerInv, TeritaryImageInv, TeritaryText, LaurelHeading, LaurelSubTitle } from './components/caseStudy.styles';
import LaurelIcon from '../../assets/images/laurel.png'
import axios from 'axios';
import ScrollToTop from '../../global/components/scrollToTop/scrollToTop';
import { db } from '../../database/firebase';

// Main
export const CaseStudy = () => {

    const navigate = useNavigate()

    const location = useLocation();

    console.log(location.pathname)

    const { projectId } = useParams();

    const [caseStudy, setCaseStudy] = useState()

    const caseStudyCollectionRef = collection(db, "projects")

    useEffect(async () => {
        // const data = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/projects/fetch/${projectId}`)
        // setCaseStudy(data.data)

        const getCaseStudy = async () => {
            const data = await getDoc(doc(db, 'projects', projectId))
            setCaseStudy(data.data());
        };
        
        getCaseStudy()
    }, [])

    const redirect = () => {
        navigate("*")
    }

    console.log(caseStudy)

    return (
        <>
            {
                caseStudy?.title ?
                    <>
                        <ScrollToTop />
                        <Navbar
                            currentPage={location.pathname}
                            caseStudy={projectId}
                        />
                        <PageStart />
                        <Squeeze>
                            <HeadingSB>
                                {caseStudy?.title}
                            </HeadingSB>
                            <HeadingItalicsSB>
                                {caseStudy?.subTitle}
                            </HeadingItalicsSB>
                            <Sandwich>
                                <MainImageContainer>
                                    <MainImage src='https://via.placeholder.com/1000' alt="MainImage" />
                                </MainImageContainer>
                            </Sandwich>
                            <SandwichL>
                                <Grid container>
                                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                                        <FlexColumnSB>
                                            <FlexboxSB>
                                                <SubTitle>Type:</SubTitle>
                                                <SubTitle>Some Info</SubTitle>
                                            </FlexboxSB>
                                            <SpacerTopBottomLarge />
                                            <FlexboxSB>
                                                <SubTitle>Type:</SubTitle>
                                                <SubTitle>Some Info</SubTitle>
                                            </FlexboxSB>
                                            <SpacerTopBottomLarge />
                                            <FlexboxSB>
                                                <SubTitle>Type:</SubTitle>
                                                <SubTitle>Some Info</SubTitle>
                                            </FlexboxSB>
                                            <SpacerTopBottomLarge />
                                            <FlexboxSB>
                                                <SubTitle>Type:</SubTitle>
                                                <SubTitle>Some Info</SubTitle>
                                            </FlexboxSB>
                                        </FlexColumnSB>
                                    </Grid>
                                    <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
                                        <SpacerTopBottomXXXL />
                                    </Grid>
                                    <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                                        <FlexColumnSB>
                                            <SubTitle>
                                                2-BHK WITH KITCHEN AND SHED, WEATHER RESISITANT STRUCTURE,
                                                OVERHEAD TANK, RAINWATER HARVESTING SYSTEM
                                            </SubTitle>
                                            <SpacerTopBottomLarge />
                                            <SubTitle>
                                                2-BHK WITH KITCHEN AND SHED, WEATHER RESISITANT STRUCTURE,
                                                OVERHEAD TANK, RAINWATER HARVESTING SYSTEM
                                            </SubTitle>
                                            <SpacerTopBottomLarge />
                                            <SubTitle>
                                                2-BHK WITH KITCHEN AND SHED, WEATHER RESISITANT STRUCTURE,
                                                OVERHEAD TANK, RAINWATER HARVESTING SYSTEM
                                            </SubTitle>
                                        </FlexColumnSB>
                                    </Grid>
                                </Grid>
                            </SandwichL>
                            <SandwichL>
                                <Grid container>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <SecondaryImageContainer>
                                            <SecondaryImage src='https://via.placeholder.com/500' alt="MainImage" />
                                        </SecondaryImageContainer>
                                        <SpacerTopLarge>
                                            <BodyLH2>
                                                2-BHK WITH KITCHEN AND SHED, WEATHER RESISITANT STRUCTURE,
                                                OVERHEAD TANK, RAINWATER HARVESTING SYSTEM
                                            </BodyLH2>
                                        </SpacerTopLarge>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ position: 'relative' }}>
                                        <TeritaryImageContainer>
                                            <TeritaryImage src='https://via.placeholder.com/400' alt="MainImage" />
                                        </TeritaryImageContainer>
                                    </Grid>
                                </Grid>
                            </SandwichL>
                            <SpacerTopBottomXL>
                                <SandwichL>
                                    <QuaternaryImageContainer>
                                        <QuaternaryImage src='https://via.placeholder.com/700' alt="MainImage" />
                                    </QuaternaryImageContainer>
                                </SandwichL>
                            </SpacerTopBottomXL>
                            <SandwichL>
                                <Grid container>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <TeritaryImageContainerInv>
                                            <TeritaryImageInv src='https://via.placeholder.com/400' alt="MainImage" />
                                        </TeritaryImageContainerInv>
                                        <SpacerTopLarge>
                                            <TeritaryText>
                                                <BodyLH2>
                                                    2-BHK WITH KITCHEN AND SHED, WEATHER RESISITANT STRUCTURE,
                                                    OVERHEAD TANK, RAINWATER HARVESTING SYSTEM
                                                </BodyLH2>
                                            </TeritaryText>
                                        </SpacerTopLarge>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <SecondaryImageContainerInv>
                                            <SecondaryImageInv src='https://via.placeholder.com/500' alt="MainImage" />
                                        </SecondaryImageContainerInv>
                                    </Grid>
                                </Grid>
                            </SandwichL>
                            <Container>
                                <Grid container>
                                    <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                                        <LaurelImageContainer>
                                            <LaurelText>
                                                24 +
                                            </LaurelText>
                                            <LaurelImage src={LaurelIcon} />
                                        </LaurelImageContainer>
                                    </Grid>
                                    <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
                                        <FlexColumnSB>
                                            <SpacerTopBottomLarge />
                                            <Center>
                                                <LaurelHeading>
                                                    Achievement
                                                </LaurelHeading>
                                                <SpacerTopLarge>
                                                    <LaurelSubTitle>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                                    </LaurelSubTitle>
                                                </SpacerTopLarge>
                                                <SpacerTopLarge>
                                                    <LaurelSubTitle>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                                    </LaurelSubTitle>
                                                </SpacerTopLarge>
                                                <SpacerTopLarge>
                                                    <LaurelSubTitle>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    </LaurelSubTitle>
                                                </SpacerTopLarge>
                                            </Center>
                                            <SpacerTopBottomLarge />
                                        </FlexColumnSB>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Squeeze>
                        <Footer />
                    </>
                    :
                    redirect()
            }
        </>
    );
};