export const lineHeights = {
  title: "28px",
  copy: "20px",
};

export const space = [
  "0px",
  "4px",
  "8px",
  "16px",
  "32px",
  "40px",
  "56px",
  "64px",
  "72px",
  "80px",
  "88px",
  "96px",
  "104px",
  "108px",
  "116px",
  "132px",
  "140px",
  "156px",
  "164px",
  "172px",
  "180px",
];