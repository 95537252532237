import styled from 'styled-components'

export const NFPCover = styled.div`

`;

export const NFPImageContainer = styled.div`
    margin: 200px auto 0 auto;
    width: 600px;

    @media only screen and (max-width: 850px) {
        width: 400px;
    }
`;

export const NFPImage = styled.img`
    width: 100%;
    height: 100%;
`;
