// Defaults
import React from 'react';

// Ext-libs
import { Grid } from '@mui/material';

// Int-libs
import { SpacerTopBottomLarge, SpacerTopBottomMedium } from '../../../design/spacer/spacer';
import {
    AlignCenter,
    AlignRight,
    Body,
    BodyJLHmedium,
    BodyLH2,
    Bottom,
    Center,
    CustomLink,
    Middle,
    SubHeading,
    SubTitle,
    Title,
    TitleLink,
    Top
} from '../../../global/global.styles';
import { CardInfo, ExpertiseCardCover, ExpertiseCardImage } from './home.styles';

export const ExpertiseCardLeft = (props) => {
    return (
        <>
            <ExpertiseCardCover onClick={props.click}>
                <Grid container>
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <ExpertiseCardImage
                            src={props.image}
                            alt={props.alt}

                        />
                    </Grid>
                    <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
                        <CardInfo>
                            <Top>
                                <SubHeading>
                                    {props.heading}
                                </SubHeading>
                                <SpacerTopBottomLarge />
                                <BodyJLHmedium>
                                    {props.description}
                                </BodyJLHmedium>
                            </Top>
                            <SpacerTopBottomMedium />
                            <AlignRight>
                                <Body style={{ color: '#0500FF' }}>
                                    Explore our projects
                                </Body>
                            </AlignRight>
                        </CardInfo>
                    </Grid>
                </Grid>
            </ExpertiseCardCover>
        </>
    );
};

export const ExpertiseCardRight = (props) => {
    return (
        <>
            <ExpertiseCardCover onClick={props.click}>
                <Grid container>
                    <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
                        <CardInfo>
                            <Top>
                                <AlignRight>
                                    <SubHeading>
                                        {props.heading}
                                    </SubHeading>
                                </AlignRight>
                                <SpacerTopBottomLarge />
                                <AlignRight>
                                    <BodyJLHmedium>
                                        {props.description}
                                    </BodyJLHmedium>
                                </AlignRight>
                            </Top>
                            <SpacerTopBottomMedium />
                            <Body style={{ color: '#0500FF' }}>
                                Explore our projects
                            </Body>
                        </CardInfo>
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <ExpertiseCardImage
                            src={props.image}
                            alt={props.alt}
                        />
                    </Grid>
                </Grid>
            </ExpertiseCardCover>
        </>
    );
};

export const ExpertiseCardCenter = (props) => {
    return (
        <>
            <ExpertiseCardCover onClick={props.click}>
                <CardInfo>
                    <Top>
                        <AlignCenter>
                            <SubHeading>
                                {props.heading}
                            </SubHeading>
                        </AlignCenter>
                        <SpacerTopBottomLarge />
                        <div>
                            <ExpertiseCardImage
                                src={props.image}
                                alt={props.alt}
                            />
                        </div>
                        <SpacerTopBottomLarge />
                        <AlignCenter>
                            <BodyJLHmedium style={{ textAlign: 'justify' }}>
                                {props.description}
                            </BodyJLHmedium>
                        </AlignCenter>
                    </Top>
                    <SpacerTopBottomLarge />
                    <AlignCenter>
                        <Body style={{ color: '#0500FF' }}>
                            Explore our projects
                        </Body>
                    </AlignCenter>
                </CardInfo>
            </ExpertiseCardCover>
        </>
    );
};