// Defaults
import React from 'react';
import { SpacerBottomXXXL, SpacerBottomXXXXL, SpacerLeftRightMedium, SpacerLeftRightXXL, SpacerLeftRightXXXL, SpacerLeftRightXXXXL, SpacerLeftSmall, SpacerLeftXXL, SpacerLeftXXXL, SpacerLeftXXXXL, SpacerRightXXL, SpacerRightXXXL, SpacerRightXXXXL, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXL, SpacerTopBottomXXL, SpacerTopBottomXXXXL, SpacerTopLarge } from '../../../design/spacer/spacer';
import { AlignCenter, AlignRight, Body, Container, Flex, Flexbox, FlexboxSB, FooterBottom, FooterColumn, FooterContact, FooterContactBox, FooterContactText, FooterContainer, FooterCover, FooterLeft, FooterRight, FooterSubTitle, FooterTitle, LastLine, Left, Right, Sandwich, Squeeze, SubTitle, Title } from '../../global.styles';

// Ext-libs
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import useWindowDimensions from '../screenSize/screenSize';
import { FooterMenuItem } from './footerMenuItem';
import { useNavigate } from 'react-router-dom';
import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';

// Int-libs


// Main
export const Footer = () => {

    const { height, width } = useWindowDimensions();

    const navigate = useNavigate();

    return (
        <>
            {
                width > 850
                    ?
                    <FooterCover>
                        <FooterContainer>
                            <FlexboxSB>
                                <Left>
                                    <Flex>
                                        <FooterColumn>
                                            <FooterTitle>Navigate</FooterTitle>
                                            <SpacerTopBottomXXL>
                                                <FooterSubTitle onClick={() => { navigate("/") }}>Home</FooterSubTitle>
                                            </SpacerTopBottomXXL>
                                            <SpacerTopBottomXXL>
                                                <FooterSubTitle onClick={() => { navigate("/projects") }}>Our Work</FooterSubTitle>
                                            </SpacerTopBottomXXL>
                                            <SpacerTopBottomXXL>
                                                <FooterSubTitle onClick={() => { navigate("/about") }}>About Us</FooterSubTitle>
                                            </SpacerTopBottomXXL>
                                            <SpacerTopBottomXXL>
                                                <FooterSubTitle onClick={() => { navigate("/contact") }}>Contact</FooterSubTitle>
                                            </SpacerTopBottomXXL>
                                            <SpacerTopBottomXXL>
                                                <FooterSubTitle onClick={() => { navigate("/contact") }}>Careers</FooterSubTitle>
                                            </SpacerTopBottomXXL>
                                        </FooterColumn>
                                        <FooterColumn>
                                            <FooterTitle>Services</FooterTitle>
                                            <SpacerTopBottomXXL>
                                                <FooterSubTitle>Construction</FooterSubTitle>
                                            </SpacerTopBottomXXL>
                                            <SpacerTopBottomXXL>
                                                <FooterSubTitle>Supervision</FooterSubTitle>
                                            </SpacerTopBottomXXL>
                                            <SpacerTopBottomXXL>
                                                <FooterSubTitle>Maintenance</FooterSubTitle>
                                            </SpacerTopBottomXXL>
                                            <SpacerTopBottomXXL>
                                                <FooterSubTitle>Consulting</FooterSubTitle>
                                            </SpacerTopBottomXXL>
                                            <SpacerTopBottomXXL>
                                                <FooterSubTitle>Sales</FooterSubTitle>
                                            </SpacerTopBottomXXL>
                                        </FooterColumn>
                                        <FooterColumn>
                                            <FooterTitle>Legal</FooterTitle>
                                            <SpacerTopBottomXXL>
                                                <a href='/terms' target="_blank" style={{ textDecoration: 'none' }}>
                                                    <FooterSubTitle>Terms</FooterSubTitle>
                                                </a>
                                            </SpacerTopBottomXXL>
                                            <SpacerTopBottomXXL>
                                                <a href='/privacypolicy' target="_blank" style={{ textDecoration: 'none' }}>
                                                    <FooterSubTitle>Privacy</FooterSubTitle>
                                                </a>
                                            </SpacerTopBottomXXL>
                                        </FooterColumn>
                                        <FooterColumn>
                                            <FooterTitle>Resources</FooterTitle>
                                            <SpacerTopBottomXXL>
                                                <a href='/attribution' target="_blank" style={{ textDecoration: 'none' }}>
                                                    <FooterSubTitle>Attribution</FooterSubTitle>
                                                </a>
                                            </SpacerTopBottomXXL>
                                            <SpacerTopBottomXXL>
                                                <a href='https://www.srisaiengineers.com/sitemap.xml' target="_blank" style={{ textDecoration: 'none' }}>
                                                    <FooterSubTitle>Sitemap</FooterSubTitle>
                                                </a>
                                            </SpacerTopBottomXXL>
                                        </FooterColumn>
                                    </Flex>
                                </Left>
                                <Right>
                                    <FooterContact>
                                        <FooterContactBox>
                                            <AlignRight>
                                                <FooterContactText>
                                                    #74, Phoenix building,
                                                </FooterContactText>
                                                <FooterContactText>
                                                    Hennur main road,
                                                </FooterContactText>
                                                <FooterContactText>
                                                    Near Indian Academy
                                                </FooterContactText>
                                                <FooterContactText>
                                                    Opposite New Karavalli restaurant
                                                </FooterContactText>
                                                <FooterContactText>
                                                    Bangalore, Karnataka,
                                                </FooterContactText>
                                                <FooterContactText>
                                                    560043
                                                </FooterContactText>
                                                <SpacerTopLarge>
                                                    <FooterContactText>
                                                        chandravadana@srisaiengineers.in                                                    </FooterContactText>
                                                    <FooterContactText>
                                                        +91 9972144127
                                                    </FooterContactText>
                                                </SpacerTopLarge>
                                            </AlignRight>
                                        </FooterContactBox>
                                    </FooterContact>
                                    {/* <SpacerTopBottomLarge>
                                        {
                                            width > 1100
                                                ?
                                                <AlignCenter>
                                                    <EmailRoundedIcon
                                                        style={{ color: '#CBCBD4', fontSize: '48px', margin: '0 8px', cursor: 'pointer' }}
                                                    />
                                                    <LinkedInIcon
                                                        style={{ color: '#CBCBD4', fontSize: '48px', margin: '0 8px', cursor: 'pointer' }}
                                                    />
                                                    <FacebookRoundedIcon
                                                        style={{ color: '#CBCBD4', fontSize: '48px', margin: '0 8px', cursor: 'pointer' }}
                                                    />
                                                    <InstagramIcon
                                                        style={{ color: '#CBCBD4', fontSize: '48px', margin: '0 8px', cursor: 'pointer' }}
                                                    />
                                                    <TwitterIcon
                                                        style={{ color: '#CBCBD4', fontSize: '48px', margin: '0 8px', cursor: 'pointer' }}
                                                    />
                                                </AlignCenter>
                                                :
                                                <AlignCenter>
                                                    <EmailRoundedIcon
                                                        style={{ color: '#CBCBD4', fontSize: '40px', margin: '0 8px', cursor: 'pointer' }}
                                                    />
                                                    <LinkedInIcon
                                                        style={{ color: '#CBCBD4', fontSize: '40px', margin: '0 8px', cursor: 'pointer' }}
                                                    />
                                                    <FacebookRoundedIcon
                                                        style={{ color: '#CBCBD4', fontSize: '40px', margin: '0 8px', cursor: 'pointer' }}
                                                    />
                                                    <InstagramIcon
                                                        style={{ color: '#CBCBD4', fontSize: '40px', margin: '0 8px', cursor: 'pointer' }}
                                                    />
                                                    <TwitterIcon
                                                        style={{ color: '#CBCBD4', fontSize: '40px', margin: '0 8px', cursor: 'pointer' }}
                                                    />
                                                </AlignCenter>
                                        }
                                    </SpacerTopBottomLarge> */}
                                </Right>
                            </FlexboxSB>
                        </FooterContainer>
                        <Squeeze>
                            <LastLine>
                                <FlexboxSB style={{ position: 'relative' }}>
                                    <FooterLeft>
                                        <Flexbox>
                                            <CopyrightOutlinedIcon sx={{ color: '#A6A6AA' }} />
                                            <SpacerLeftSmall />
                                            <Body style={{ color: '#A6A6AA' }}>{new Date().getFullYear()} Sri Sai Engineers</Body>
                                            <SpacerLeftRightMedium>
                                                <FiberManualRecordRoundedIcon sx={{ fontSize: 8, color: '#A6A6AA' }} />
                                            </SpacerLeftRightMedium>
                                            <Body style={{ color: '#A6A6AA' }}>All rights reserved</Body>
                                        </Flexbox>
                                    </FooterLeft>
                                    <FooterRight href='https://www.inspirise.com/' target="_blank">
                                        Site by: Inspirise
                                    </FooterRight>
                                </FlexboxSB>
                            </LastLine>
                        </Squeeze>
                    </FooterCover>
                    :
                    <FooterCover>
                        <FooterBottom>
                            <FooterContainer>
                                <FooterContactBox>
                                    <AlignRight>
                                        <FooterContactText>
                                            #74, Phoenix building,
                                        </FooterContactText>
                                        <FooterContactText>
                                            Hennur main road,
                                        </FooterContactText>
                                        <FooterContactText>
                                            Near Indian Academy
                                        </FooterContactText>
                                        <FooterContactText>
                                            Opposite New Karavalli restaurant
                                        </FooterContactText>
                                        <FooterContactText>
                                            Bangalore, Karnataka,
                                        </FooterContactText>
                                        <FooterContactText>
                                            560043
                                        </FooterContactText>
                                        <SpacerTopLarge>
                                            <FooterContactText>
                                                chandravadana@srisaiengineers.in                                            </FooterContactText>
                                            <FooterContactText>
                                                +91 9972144127
                                            </FooterContactText>
                                        </SpacerTopLarge>
                                    </AlignRight>
                                </FooterContactBox>
                                {/* <SpacerTopLarge>
                                    <AlignCenter>
                                        <EmailRoundedIcon
                                            style={{ color: '#CBCBD4', fontSize: '40px', margin: '0 8px', cursor: 'pointer' }}
                                        />
                                        <LinkedInIcon
                                            style={{ color: '#CBCBD4', fontSize: '40px', margin: '0 8px', cursor: 'pointer' }}
                                        />
                                        <FacebookRoundedIcon
                                            style={{ color: '#CBCBD4', fontSize: '40px', margin: '0 8px', cursor: 'pointer' }}
                                        />
                                        <InstagramIcon
                                            style={{ color: '#CBCBD4', fontSize: '40px', margin: '0 8px', cursor: 'pointer' }}
                                        />
                                        <TwitterIcon
                                            style={{ color: '#CBCBD4', fontSize: '40px', margin: '0 8px', cursor: 'pointer' }}
                                        />
                                    </AlignCenter>
                                </SpacerTopLarge> */}
                                <Sandwich>
                                    <FooterMenuItem
                                        menuItem={"Navigate"}
                                        linkItem1={"Home"}
                                        link1={"/"}
                                        linkItem2={"Our Work"}
                                        link2={"/projects"}
                                        linkItem3={"About Us"}
                                        link3={"/about"}
                                        linkItem4={"Contact"}
                                        link4={"/contact"}
                                        linkItem5={"Careers"}
                                        link5={"/contact"}
                                    />
                                    <SpacerTopLarge>
                                        <FooterMenuItem
                                            menuItem={"Services"}
                                            linkItem1={"Construction"}
                                            linkItem2={"Supervision"}
                                            linkItem3={"Maintenance"}
                                            linkItem4={"Consulting"}
                                            linkItem5={"Sales"}
                                        />
                                    </SpacerTopLarge>
                                    <SpacerTopLarge>
                                        <FooterMenuItem
                                            menuItem={"Legal"}
                                            linkItem1={"Terms"}
                                            anchorLink1={"/terms"}
                                            linkItem2={"Privacy"}
                                            anchorLink2={"/privacyPolicy"}
                                        />
                                    </SpacerTopLarge>
                                    <SpacerTopLarge>
                                        <FooterMenuItem
                                            menuItem={"Resources"}
                                            linkItem1={"Attribution"}
                                            anchorLink1={"/attribution"}
                                            linkItem2={"Sitemap"}
                                            anchorLink2={"https://www.srisaiengineers.com/sitemap.xml"}
                                        />
                                    </SpacerTopLarge>
                                </Sandwich>
                            </FooterContainer>
                            <AlignCenter>
                                <Flexbox style={{ justifyContent: 'space-around' }}>
                                    <Flexbox>
                                        <CopyrightOutlinedIcon sx={{ color: '#A6A6AA' }} />
                                        <SpacerLeftSmall />
                                        <Body style={{ color: '#A6A6AA', fontSize: '20px' }}>{new Date().getFullYear()} Sri Sai Engineers</Body>
                                        <SpacerLeftRightMedium>
                                            <FiberManualRecordRoundedIcon sx={{ fontSize: 8, color: '#A6A6AA' }} />
                                        </SpacerLeftRightMedium>
                                        <Body style={{ color: '#A6A6AA', fontSize: '20px' }}>All rights reserved</Body>
                                    </Flexbox>
                                </Flexbox>
                                <SpacerTopBottomXL>
                                    <a
                                        href='https://www.inspirise.com/' target="_blank"
                                        style={{ fontSize: '20px', color: '#A6A6AA', textDecoration: 'none' }}>
                                        Site by: Inspirise
                                    </a>
                                </SpacerTopBottomXL>
                            </AlignCenter>
                        </FooterBottom>
                    </FooterCover>
            }
        </>
    );
};