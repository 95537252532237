// Defaults
import React from 'react';

// Ext-libs
import { useNavigate } from "react-router-dom";

// Int-libs
import { HeroSection } from './components/heroSection';
import { Container, Sandwich, Squeeze } from '../../global/global.styles';
import { Expertise } from './components/expertise';
import { Testimonial } from './components/testimonial';
import { CallToAction } from './components/callToAction';
import { Footer } from '../../global/components/footer/footer';
import ScrollToTop from '../../global/components/scrollToTop/scrollToTop';
import useWindowDimensions from '../../global/components/screenSize/screenSize';
import { ExpertiseCards } from './components/expertiseCards';
import { SpacerBottomLarge, SpacerBottomXXL, SpacerBottomXXXL } from '../../design/spacer/spacer';
import Slider from './components/autoCarousel/slider';

// Main
export const Home = () => {

    const navigate = useNavigate()

    return (
        <>
            <ScrollToTop />
            <HeroSection
                click={() => {
                    navigate('/GetStarted')
                }}
            />
            <Container>
                <Expertise />
                <Sandwich />
                <ExpertiseCards />
            </Container>
            {/* <Slider /> */}
            <CallToAction />
            <Footer />
        </>
    );
};