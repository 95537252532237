import styled from 'styled-components'

export const MainImageContainer = styled.div`
    width: 100%;
    height: 450px;
`;

export const MainImage = styled.img`
    width: 100%;
    height: 100%;
`;

export const SecondaryImageContainer = styled.div`
   width: 100%;
   height: 550px;
`;

export const SecondaryImage = styled.img`
    width: 100%;
    height: 100%;
`;

export const TeritaryImageContainer = styled.div`
    width: 250px;
    height: 350px;
    position: absolute;
    bottom: 0;
    right: 0;

    @media only screen and (max-width: 850px) {
        position: static;
        margin-top: 80px;
    }
`;

export const TeritaryImage = styled.img`
    width: 100%;
    height: 100%;
`;

export const QuaternaryImageContainer = styled.div`
    width: 80%;
    height: 450px;
    margin-left: auto;
`;

export const QuaternaryImage = styled.img`
    width: 100%;
    height: 100%;
`;

export const SecondaryImageContainerInv = styled.div`
   width: 100%;
   height: 550px;
`;

export const SecondaryImageInv = styled.img`
    width: 100%;
    height: 100%;
`;

export const TeritaryImageContainerInv = styled.div`
    width: 250px;
    height: 350px;
`;

export const TeritaryImageInv = styled.img`
    width: 100%;
    height: 100%;
`;

export const LaurelImageContainer = styled.div`
    width: 250px;
    position: relative;

    @media only screen and (max-width: 850px) {
        margin: 0 auto;
    }
`;

export const LaurelImage = styled.img`
    width: 100%;
    height: 100%;
`;

export const LaurelText = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subCaption};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const TeritaryText = styled.div`
    width: 250px;

    @media only screen and (max-width: 850px) {
        width: 100%;
        margin-bottom: 80px;
    }
`;

export const LaurelHeading = styled.div`
    font-size: ${(props) => props.theme.fontSizes.heading};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.text.grey80};

    @media only screen and (max-width: 850px) {
        text-align: center;
    }
`;

export const LaurelSubTitle = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    color: ${(props) => props.theme.colors.text.grey80};

    @media only screen and (max-width: 850px) {
        text-align: justify;
    }
`;