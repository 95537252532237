// Defaults
import React from 'react';

// Ext-libs
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useNavigate } from 'react-router-dom';
import { Footer } from '../../global/components/footer/footer';
import { Button, Card, Grid } from '@mui/material';

// Int-libs
import { AspirantForm } from './components/aspirantsForm';
import ScrollToTop from '../../global/components/scrollToTop/scrollToTop';
import { AspirantFormContainer, AspirantInput, AspirantLabel, CenterAbout, ContactCTAButton, ContactFlexboxSE, ContactHRManager, ContactSocialIcons, ContactSubHeading, MapContainer, OfficeAddressContainer, RolesAvailable } from './components/contact.styles';
import { SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXL } from '../../design/spacer/spacer';
import { Navbar } from '../../global/components/navbar/navbar';
import { AlignRight, Body, BodyLH2, Bottom, Center, Container, ContainerGreen, ContainerGrey, ContainerYellow, Flexbox, FlexboxSA, FlexboxSB, FlexboxSE, FlexColumnSB, PageStart, Squeeze, SubHeading, SubTitle, Title, Top } from '../../global/global.styles';
import { CardInfo, CtaInput, CtaLabel, MainCTAButton } from '../home/components/home.styles';

// Main
export const Contact = () => {

    const navigate = useNavigate()

    return (
        <>
            <ScrollToTop />
            <Navbar />
            <PageStart />
            <Squeeze>
                <SubHeading>
                    Office Address
                </SubHeading>
            </Squeeze>
            <SpacerTopBottomXL />
            <OfficeAddressContainer>
                <Grid container>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <FlexColumnSB>
                            <Top>
                                <SubTitle>
                                    Head Office
                                </SubTitle>
                                <SpacerTopBottomMedium />
                                <BodyLH2>
                                    #74, Phoenix building, Hennur main road,
                                </BodyLH2>
                                <BodyLH2>
                                    Near Indian Academy,
                                </BodyLH2>
                                <BodyLH2>
                                    Opposite New Karavalli restaurant,
                                </BodyLH2>
                                <BodyLH2>
                                    Bangalore, Karnataka,
                                </BodyLH2>
                                <BodyLH2>
                                    Pincode: 560043
                                </BodyLH2>
                            </Top>
                            <CenterAbout>
                                <SubTitle>
                                    Chief Engineer
                                </SubTitle>
                                <SpacerTopBottomMedium />
                                <BodyLH2>
                                    Email: chandravadana@srisaiengineers.in
                                </BodyLH2>
                                {/* <BodyLH2>
                                    Telephone: 080 12345678
                                </BodyLH2> */}
                                <BodyLH2>
                                    Mobile: +91 9972144127
                                </BodyLH2>
                            </CenterAbout>
                            {/* <ContactSocialIcons>
                                <EmailRoundedIcon
                                    style={{
                                        fontSize: '40px',
                                        margin: '0 8px',
                                        cursor: 'pointer',
                                    }}
                                />
                                <LinkedInIcon
                                    style={{
                                        fontSize: '40px',
                                        margin: '0 8px',
                                        cursor: 'pointer'
                                    }}
                                />
                                <FacebookRoundedIcon
                                    style={{
                                        fontSize: '40px',
                                        margin: '0 8px',
                                        cursor: 'pointer'
                                    }}
                                />
                                <InstagramIcon
                                    style={{
                                        fontSize: '40px',
                                        margin: '0 8px',
                                        cursor: 'pointer'
                                    }}
                                />
                                <TwitterIcon
                                    style={{
                                        fontSize: '40px',
                                        margin: '0 8px',
                                        cursor: 'pointer'
                                    }}
                                />
                            </ContactSocialIcons> */}
                        </FlexColumnSB>
                    </Grid>
                    <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                        <MapContainer>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15548.364927410836!2d77.6347272!3d13.029862!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe2cd03dd86f35c0!2sSri%20Sai%20Engineers!5e0!3m2!1sen!2sin!4v1647921111767!5m2!1sen!2sin" width="100%" height="100%" style={{ border: 0 }} allowFullScreen="" loading="lazy"></iframe>
                        </MapContainer>
                    </Grid>
                </Grid>
            </OfficeAddressContainer>
            <ContainerGreen>
                <ContactFlexboxSE>
                    <ContactSubHeading>
                        Fill out this form and our team will connect with you:
                    </ContactSubHeading>
                    <ContactCTAButton
                        variant="contained"
                        disableElevation
                        className={'customStyle'}
                        onClick={() => {
                            navigate('/GetStarted')
                        }}
                    >
                        Get Started
                    </ContactCTAButton>
                </ContactFlexboxSE>
            </ContainerGreen>
            <ContainerYellow>
                <SubHeading>
                    Careers
                </SubHeading>
                <SpacerTopBottomXL />
                <Grid container>
                    <Grid item xl={7} lg={7} md={5} sm={12} xs={12}>
                        <FlexColumnSB>
                            <Top>
                                <RolesAvailable>
                                    <Body style={{ color: 'grey', fontStyle: 'italic' }}>
                                        (Currently, no roles available)
                                    </Body>
                                </RolesAvailable>
                            </Top>
                            <ContactHRManager>
                                <SubTitle>
                                    HR Manager
                                </SubTitle>
                                <SpacerTopBottomMedium />
                                <BodyLH2>
                                    Email: recruitment@srisaiengineers.in
                                </BodyLH2>
                                {/* <BodyLH2>
                                    Telephone: 080 12345678
                                </BodyLH2> */}
                                <BodyLH2>
                                    Mobile: +91 9008616815
                                </BodyLH2>
                            </ContactHRManager>
                        </FlexColumnSB>
                    </Grid>
                    <Grid item xl={5} lg={5} md={7} sm={12} xs={12}>
                        <AspirantForm />
                    </Grid>
                </Grid>
            </ContainerYellow>
            <Footer />
        </>
    );
};

