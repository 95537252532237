// Defaults
import React, { useState, useEffect } from 'react';

// Ext-libs
import { Button, Grid, TextField } from '@mui/material';
import axios from 'axios'
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

// Int-libs
import { SpacerBottomXXL, SpacerTopBottomLarge, SpacerTopBottomMedium } from '../../../design/spacer/spacer';
import { AlignRight, Container, ContainerGrey, Flexbox, FlexboxSA, FlexboxSB, FlexboxSE, Sandwich, SubHeading, Title } from '../../../global/global.styles';
import { CtaInput, CtaLabel, FlexboxHomeCTA, HomeCTASuccess, LogoGridHome, LogoHome, RightFlex } from './home.styles';
import { LogoGrid } from '../../about/components/about.styles';
import CircularPH from '../../../assets/images/CircularPH.png'
import useWindowDimensions from '../../../global/components/screenSize/screenSize';
import Logo from '../../../assets/images/SriSai512x512.png'
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../../database/firebase';

// Main
export const CallToAction = () => {

    const url = process.env.REACT_APP_SERVER_HOST

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [contactNum, setContactNum] = useState("")

    const [prospectData, setProspectData] = useState({})
    const [loading, setLoading] = useState(false)
    const [submission, setSubmission] = useState()

    const prospectsCollectionRef = collection(db, "prospects")

    const handleSubmit = async () => {

        setLoading(true)

        const data = {
            name,
            email,
            contactNum
        }

        await addDoc(prospectsCollectionRef, data)

        // await axios.post(`${url}/prospects/register`, data)
        //     .then(() => {
        //         setProspectData(data)
        //         setLoading(false)
        //         setSubmission("success")
        //     });

        setName("")
        setEmail("")
        setContactNum("")

        setProspectData(data)
        setLoading(false)
        setSubmission("success")

    }

    const { height, width } = useWindowDimensions();


    return (
        <>
            <ContainerGrey>
                <Sandwich>
                    <SpacerBottomXXL>
                        <SubHeading>
                            Interested in getting connected ?
                        </SubHeading>
                    </SpacerBottomXXL>
                    <Grid container>
                        <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
                            <SpacerTopBottomLarge>
                                <FlexboxHomeCTA>
                                    <CtaLabel>Name:</CtaLabel>
                                    <CtaInput
                                        id="outlined-basic"
                                        type='text'
                                        variant="outlined"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </FlexboxHomeCTA>
                            </SpacerTopBottomLarge>
                            <SpacerTopBottomLarge>
                                <FlexboxHomeCTA>
                                    <CtaLabel>Email:</CtaLabel>
                                    <CtaInput
                                        id="outlined-basic"
                                        type='email'
                                        variant="outlined"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </FlexboxHomeCTA>
                            </SpacerTopBottomLarge>
                            <SpacerTopBottomLarge>
                                <FlexboxHomeCTA>
                                    <CtaLabel>Contact number:</CtaLabel>
                                    <CtaInput
                                        id="outlined-basic"
                                        type='number'
                                        variant="outlined"
                                        value={contactNum}
                                        onChange={(e) => setContactNum(e.target.value)}
                                    />
                                </FlexboxHomeCTA>
                            </SpacerTopBottomLarge>
                            {
                                width > 650
                                    ?
                                    loading ?
                                        <AlignRight>
                                            <LoadingButton
                                                loading
                                                loadingPosition="start"
                                                startIcon={<SaveIcon />}
                                                variant="outlined"
                                            >
                                                Submit
                                            </LoadingButton>
                                        </AlignRight>
                                        :
                                        <AlignRight>
                                            <Button
                                                variant="contained"
                                                style={{ backgroundColor: 'black' }}
                                                onClick={handleSubmit}
                                            >
                                                Submit
                                            </Button>
                                        </AlignRight>
                                    :
                                    loading ?
                                        <LoadingButton
                                            loading
                                            loadingPosition="start"
                                            startIcon={<SaveIcon />}
                                            variant="outlined"
                                            fullWidth
                                        >
                                            Submit
                                        </LoadingButton>
                                        :
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: 'black' }}
                                            onClick={handleSubmit}
                                            fullWidth
                                        >
                                            Submit
                                        </Button>
                            }
                        </Grid>
                        {
                            width > 850
                                ?
                                <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                                    <LogoHome>
                                        <img src={Logo} alt="image" style={{ width: '100%' }} />
                                    </LogoHome>
                                    {
                                        submission === "success"
                                        &&
                                        <HomeCTASuccess>
                                            Thanks for getting in touch {prospectData.name},
                                            one of our sales representatives will connect with you shortly
                                        </HomeCTASuccess>
                                    }
                                </Grid>
                                :
                                submission === "success"
                                &&
                                <HomeCTASuccess>
                                    Thanks for getting in touch {prospectData.name},
                                    one of our sales representatives will connect with you shortly
                                </HomeCTASuccess>
                        }
                    </Grid>
                </Sandwich>
            </ContainerGrey>
        </>
    );
};