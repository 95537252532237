// Defaults
import React from 'react';
import '../style/home.css'

// Ext-libs
import { useNavigate } from "react-router-dom";

// Int-libs
import {
    SpacerBottomLarge,
    SpacerBottomXXL,
    SpacerBottomXXXL,
    SpacerLeftRightLarge,
    SpacerTopBottomLarge,
    SpacerTopBottomXXL,
    SpacerTopBottomXXXL,
    SpacerTopXXL
} from '../../../design/spacer/spacer';
import constructionImg from '../../../assets/images/construction.jpg'
import consultingImg from '../../../assets/images/consulting.jpg'
import maintenanceImg from '../../../assets/images/maintenance.jpg'
import salesImg from '../../../assets/images/sales.jpg'
import supervisionImg from '../../../assets/images/supervision.jpg'
import useWindowDimensions from '../../../global/components/screenSize/screenSize';
import { AlignCenter, Container, Heading, Sandwich, Squeeze, SubHeading } from '../../../global/global.styles';
import { CapabilityCard } from './capabilityCard';
import { ExpertiseCardLeft, ExpertiseCardRight } from './expertiseCard';
import { CapabilityCardScroll } from './home.styles';

// Main
export const Expertise = () => {

    const navigate = useNavigate();

    const { height, width } = useWindowDimensions();

    return (
        <>
            <Sandwich>
                <AlignCenter>
                    <SubHeading>
                        Our Expertise
                    </SubHeading>
                </AlignCenter>
            </Sandwich>
            {
                width > 1200
                    ?
                    <Sandwich>
                        <CapabilityCardScroll>
                            <CapabilityCard
                                image={constructionImg}
                                capability={"Construction"}
                                // bg={'linear-gradient(90deg, rgba(255,213,85,1) 0%, rgba(250,127,12,1) 100%)'}
                                bg={'linear-gradient(90deg, rgba(170,170,170,1) 0%, rgba(100,101,90,1) 100%)'}
                            />
                            <CapabilityCard
                                image={supervisionImg}
                                capability={"Supervision"}
                            // bg={'linear-gradient(90deg, rgba(85,243,255,1) 0%, rgba(12,117,250,1) 100%)'}
                            bg={'linear-gradient(90deg, rgba(170,170,170,1) 0%, rgba(100,101,90,1) 100%)'}
                            />
                            <CapabilityCard
                                image={maintenanceImg}
                                capability={"Maintenance"}
                            // bg={'linear-gradient(90deg, rgba(173,85,255,1) 0%, rgba(250,12,213,1) 100%)'}
                            bg={'linear-gradient(90deg, rgba(170,170,170,1) 0%, rgba(100,101,90,1) 100%)'}
                            />
                            <CapabilityCard
                                image={consultingImg}
                                capability={"Consulting"}
                            // bg={'linear-gradient(90deg, rgba(85,255,230,1) 0%, rgba(22,209,0,1) 100%)'}
                            bg={'linear-gradient(90deg, rgba(170,170,170,1) 0%, rgba(100,101,90,1) 100%)'}
                            />
                            <CapabilityCard
                                image={salesImg}
                                capability={"Sales"}
                            // bg={'linear-gradient(90deg, rgba(255,85,174,1) 0%, rgba(250,12,12,1) 100%)'}
                            bg={'linear-gradient(90deg, rgba(170,170,170,1) 0%, rgba(100,101,90,1) 100%)'}
                            />
                        </CapabilityCardScroll>
                    </Sandwich>
                    :
                    <div className='slider'>
                        <div className='slideTrack'>
                            <div className='slide'>
                                <CapabilityCard
                                    image={constructionImg}
                                    capability={"Construction"}
                                // bg={'linear-gradient(90deg, rgba(255,213,85,1) 0%, rgba(250,127,12,1) 100%)'}
                                bg={'linear-gradient(90deg, rgba(170,170,170,1) 0%, rgba(100,101,90,1) 100%)'}
                                />
                            </div>
                            <div className='slide'>
                                <CapabilityCard
                                    image={supervisionImg}
                                    capability={"Supervision"}
                                // bg={'linear-gradient(90deg, rgba(85,243,255,1) 0%, rgba(12,117,250,1) 100%)'}
                                bg={'linear-gradient(90deg, rgba(170,170,170,1) 0%, rgba(100,101,90,1) 100%)'}
                                />
                            </div>
                            <div className='slide'>
                                <CapabilityCard
                                    image={maintenanceImg}
                                    capability={"Maintenance"}
                                // bg={'linear-gradient(90deg, rgba(173,85,255,1) 0%, rgba(250,12,213,1) 100%)'}
                                bg={'linear-gradient(90deg, rgba(170,170,170,1) 0%, rgba(100,101,90,1) 100%)'}
                                />
                            </div>
                            <div className='slide'>
                                <CapabilityCard
                                    image={consultingImg}
                                    capability={"Consulting"}
                                // bg={'linear-gradient(90deg, rgba(85,255,230,1) 0%, rgba(22,209,0,1) 100%)'}
                                bg={'linear-gradient(90deg, rgba(170,170,170,1) 0%, rgba(100,101,90,1) 100%)'}
                                />
                            </div>
                            <div className='slide'>
                                <CapabilityCard
                                    image={salesImg}
                                    capability={"Sales"}
                                // bg={'linear-gradient(90deg, rgba(255,85,174,1) 0%, rgba(250,12,12,1) 100%)'}
                                bg={'linear-gradient(90deg, rgba(170,170,170,1) 0%, rgba(100,101,90,1) 100%)'}
                                />
                            </div>

                            <div className='slide'>
                                <CapabilityCard
                                    image={constructionImg}
                                    capability={"Construction"}
                                // bg={'linear-gradient(90deg, rgba(255,213,85,1) 0%, rgba(250,127,12,1) 100%)'}
                                bg={'linear-gradient(90deg, rgba(170,170,170,1) 0%, rgba(100,101,90,1) 100%)'}
                                />
                            </div>
                            <div className='slide'>
                                <CapabilityCard
                                    image={supervisionImg}
                                    capability={"Supervision"}
                                // bg={'linear-gradient(90deg, rgba(85,243,255,1) 0%, rgba(12,117,250,1) 100%)'}
                                bg={'linear-gradient(90deg, rgba(170,170,170,1) 0%, rgba(100,101,90,1) 100%)'}
                                />
                            </div>
                            <div className='slide'>
                                <CapabilityCard
                                    image={maintenanceImg}
                                    capability={"Maintenance"}
                                // bg={'linear-gradient(90deg, rgba(173,85,255,1) 0%, rgba(250,12,213,1) 100%)'}
                                bg={'linear-gradient(90deg, rgba(170,170,170,1) 0%, rgba(100,101,90,1) 100%)'}
                                />
                            </div>
                            <div className='slide'>
                                <CapabilityCard
                                    image={consultingImg}
                                    capability={"Consulting"}
                                // bg={'linear-gradient(90deg, rgba(85,255,230,1) 0%, rgba(22,209,0,1) 100%)'}
                                bg={'linear-gradient(90deg, rgba(170,170,170,1) 0%, rgba(100,101,90,1) 100%)'}
                                />
                            </div>
                            <div className='slide'>
                                <CapabilityCard
                                    image={salesImg}
                                    capability={"Sales"}
                                // bg={'linear-gradient(90deg, rgba(255,85,174,1) 0%, rgba(250,12,12,1) 100%)'}
                                bg={'linear-gradient(90deg, rgba(170,170,170,1) 0%, rgba(100,101,90,1) 100%)'}
                                />
                            </div>
                        </div>
                    </div>
            }

        </>
    );
};