// Defaults
import React, { useEffect, useState } from 'react';
import './navbar.css'

// Ext-libs
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import EngineeringIcon from '@mui/icons-material/Engineering';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';



// Int-libs
import { SpacerLeftRightXXXXL } from '../../../design/spacer/spacer';
import {
    AbsoluteCenter,
    AlignCenter,
    Container,
    Flex,
    Flexbox,
    FlexboxSA,
    FlexboxSB,
    Left,
    Middle,
    NavSubTitle,
    NavSubTitleActive,
    NavTitle,
    PositionCenter,
    Right,
    Sandwich,
    Squeeze,
    SubHeading,
    SubTitle,
    Title,
} from '../../global.styles';
import LogoIcon from '../../../assets/images/favicon-32x32.png'
import useWindowDimensions from '../screenSize/screenSize';
import MenuIcon from '../../../assets/images/menuIconCircle.png'
import { BottomNavigation, BottomNavigationAction, Box, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Paper, SwipeableDrawer } from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

// Main
export const Navbar = (props) => {

    const navigate = useNavigate();

    const location = useLocation();

    const [scroll, setScroll] = useState(false)

    const handleScroll = () => {

        if (window.scrollY > 80) {
            setScroll(true)
        } else {
            setScroll(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    }, []);

    const { height, width } = useWindowDimensions();

    const [value, setValue] = useState(0);

    const navigateHome = () => {
        navigate("/");
    }

    const handleNavigate = () => {
        navigate("/");
        setValue(1);
    }

    const [state, setState] = useState({
        left: false,
    });

    const toggleDrawer = (left, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [left]: open });
    };

    const list = (left) => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(left, false)}
            onKeyDown={toggleDrawer(left, false)}
        >
            <List>
                {["Home", "Projects", "About", "Contact"].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemText primary={text} onClick={() => {
                            text === "Home"
                                ?
                                navigate("/")
                                :
                                navigate(`/${text}`)
                        }} />
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {['Careers', 'Acknowledgement'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemText primary={text} onClick={() => {
                            text === "Careers"
                                ?
                                navigate("/contact")
                                :
                                navigate(`/${text}`)
                        }} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <>
            {
                width > 650
                    ?
                    <div className={scroll ? 'stickyScroll' : 'sticky'}>
                        <FlexboxSB>
                            {
                                width > 850
                                    ?
                                    <Left style={{ display: 'flex' }}>
                                        <div style={{ width: '27px', height: '27px', marginRight: '5px' }}>
                                            <img src={LogoIcon} alt='logo' style={{ width: '100%', height: '100%' }} />
                                        </div>
                                        <NavTitle to="/">
                                            Sri Sai Engineers
                                        </NavTitle>
                                    </Left>
                                    :
                                    <>
                                        <div>
                                            {[MenuIcon].map((left) => (
                                                <React.Fragment key={left}>
                                                    <div style={{ width: '40px', height: '40px', cursor: 'pointer' }}>
                                                        <img
                                                            src={left} alt="Menu"
                                                            style={{ width: '100%', height: '100%' }}
                                                            onClick={toggleDrawer(left, true)}
                                                        />
                                                    </div>
                                                    <SwipeableDrawer
                                                        left={left}
                                                        open={state[left]}
                                                        onClose={toggleDrawer(left, false)}
                                                        onOpen={toggleDrawer(left, true)}
                                                    >
                                                        {list(left)}
                                                    </SwipeableDrawer>
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </>
                            }
                            <Middle>
                                <PositionCenter>
                                    {
                                        width > 850
                                            ?
                                            <FlexboxSB>
                                                <Left>
                                                    {
                                                        props.currentPage === '/'
                                                            ?
                                                            <SpacerLeftRightXXXXL>
                                                                <NavSubTitleActive to="/">Home</NavSubTitleActive>
                                                            </SpacerLeftRightXXXXL>
                                                            :
                                                            <SpacerLeftRightXXXXL>
                                                                <NavSubTitle to="/">Home</NavSubTitle>
                                                            </SpacerLeftRightXXXXL>
                                                    }
                                                </Left>
                                                <Middle>
                                                    {
                                                        props.currentPage === '/projects' || props.currentPage === `/projects/${props.caseStudy}`
                                                            ?
                                                            <SpacerLeftRightXXXXL>
                                                                <NavSubTitleActive to="/projects">Our Work</NavSubTitleActive>
                                                            </SpacerLeftRightXXXXL>
                                                            :
                                                            <SpacerLeftRightXXXXL>
                                                                <NavSubTitle to="/projects">Our Work</NavSubTitle>
                                                            </SpacerLeftRightXXXXL>
                                                    }
                                                </Middle>
                                                <Right>
                                                    {
                                                        props.currentPage === '/about'
                                                            ?
                                                            <SpacerLeftRightXXXXL>
                                                                <NavSubTitleActive to="/about">About Us</NavSubTitleActive>
                                                            </SpacerLeftRightXXXXL>
                                                            :
                                                            <SpacerLeftRightXXXXL>
                                                                <NavSubTitle to="/about">About Us</NavSubTitle>
                                                            </SpacerLeftRightXXXXL>
                                                    }
                                                </Right>
                                            </FlexboxSB>
                                            :
                                            <Left style={{ display: 'flex' }}>
                                                <div style={{ width: '28px', height: '28px', marginRight: '5px' }}>
                                                    <img src={LogoIcon} alt='logo' style={{ width: '100%', height: '100%' }} />
                                                </div>
                                                <NavTitle to="/">
                                                    Sri Sai Engineers
                                                </NavTitle>
                                            </Left>
                                    }
                                </PositionCenter>
                            </Middle>
                            <Right>
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: "black" }}
                                    disableElevation
                                    onClick={() => { navigate('/contact'); }}
                                >
                                    Contact
                                </Button>
                            </Right>
                        </FlexboxSB>
                    </div>
                    :
                    <div>
                        <Squeeze style={{ paddingTop: '32px', paddingBottom: '32px' }}>
                            {
                                location.pathname === '/'
                                    ?
                                    width > 450
                                        ?
                                        <FlexboxSA style={{ marginTop: '40px' }}>
                                            <Flexbox>
                                                <div style={{ width: '32px', height: '32px', marginRight: '5px' }}>
                                                    <img src={LogoIcon} alt='logo' style={{ width: '100%', height: '100%' }} />
                                                </div>
                                                <SubHeading>Sri Sai Engineers</SubHeading>
                                            </Flexbox>
                                        </FlexboxSA>
                                        :
                                        <FlexboxSA style={{ marginTop: '24px' }}>
                                            <Flexbox>
                                                <div style={{ width: '28px', height: '28px', marginRight: '5px' }}>
                                                    <img src={LogoIcon} alt='logo' style={{ width: '100%', height: '100%' }} />
                                                </div>
                                                <Title>Sri Sai Engineers</Title>
                                            </Flexbox>
                                        </FlexboxSA>
                                    :
                                    width > 350
                                        ?
                                        <FlexboxSB>
                                            <Flexbox>
                                                <div style={{ width: '32px', height: '32px', marginRight: '5px' }}>
                                                    <img src={LogoIcon} alt='logo' style={{ width: '100%', height: '100%' }} />
                                                </div>
                                                <Title onClick={navigateHome}>Sri Sai Engineers</Title>
                                            </Flexbox>
                                            {
                                                location.pathname != "/contact"
                                                &&
                                                <Right>
                                                    <Button
                                                        variant="contained"
                                                        style={{ backgroundColor: "black" }}
                                                        disableElevation
                                                        onClick={() => { navigate('/contact'); }}
                                                        size='small'
                                                    >
                                                        Contact
                                                    </Button>
                                                </Right>
                                            }
                                        </FlexboxSB>
                                        :
                                        <FlexboxSB>
                                            <Flexbox>
                                                <div style={{ width: '24px', height: '24px', marginRight: '5px' }}>
                                                    <img src={LogoIcon} alt='logo' style={{ width: '100%', height: '100%' }} />
                                                </div>
                                                <SubTitle onClick={navigateHome}>Sri Sai Engineers</SubTitle>
                                            </Flexbox>
                                            {
                                                location.pathname != "/contact"
                                                &&
                                                <Right>
                                                    <Button
                                                        variant="contained"
                                                        style={{ backgroundColor: "black" }}
                                                        disableElevation
                                                        onClick={() => { navigate('/contact'); }}
                                                        size='small'
                                                    >
                                                        Contact
                                                    </Button>
                                                </Right>
                                            }
                                        </FlexboxSB>
                            }
                        </Squeeze>
                        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: '2' }} elevation={5}>
                            <BottomNavigation
                                showLabels
                                value={value}
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                }}
                                sx={{
                                    bgcolor: '#F8F7FA',
                                    '& .Mui-selected': {
                                        '& .MuiBottomNavigationAction-label': {
                                            fontSize: theme => theme.typography.caption,
                                            transition: 'none',
                                            fontWeight: 'bold',
                                            lineHeight: '20px'
                                        },
                                        '& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label': {
                                            color: '#636364'
                                        }
                                    }
                                }}
                            >
                                {
                                    location.pathname === '/'
                                        ?
                                        <BottomNavigationAction
                                            label="Home"
                                            icon={<HomeIcon />}
                                            onClick={handleNavigate}
                                        />
                                        :
                                        <BottomNavigationAction
                                            label="Home"
                                            icon={<HomeOutlinedIcon />}
                                            onClick={handleNavigate}
                                        />
                                }
                                {
                                    location.pathname === '/projects' || location.pathname === `/projects/${props.caseStudy}`
                                        ?
                                        <BottomNavigationAction
                                            label="Projects"
                                            icon={<EngineeringIcon />}
                                            onClick={() => { navigate("/projects") }}
                                        />
                                        :
                                        <BottomNavigationAction
                                            label="Projects"
                                            icon={<EngineeringOutlinedIcon />}
                                            onClick={() => { navigate("/projects") }}
                                        />
                                }
                                {
                                    location.pathname === '/about'
                                        ?
                                        <BottomNavigationAction
                                            label="About"
                                            icon={<EmojiEmotionsIcon />}
                                            onClick={() => { navigate("/about") }}
                                        />
                                        :
                                        <BottomNavigationAction
                                            label="About"
                                            icon={<EmojiEmotionsOutlinedIcon />}
                                            onClick={() => { navigate("/about") }}

                                        />
                                }
                                {
                                    location.pathname === '/contact'
                                        ?
                                        <BottomNavigationAction
                                            label="Contact"
                                            icon={<LocationOnIcon />}
                                            onClick={() => { navigate("/contact") }}
                                        />
                                        :
                                        <BottomNavigationAction
                                            label="Contact"
                                            icon={<LocationOnOutlinedIcon />}
                                            onClick={() => { navigate("/contact") }}
                                        />
                                }
                            </BottomNavigation>
                        </Paper>
                    </div>
            }
        </>
    );
};