// Defaults
import React, { useState } from 'react';
import { SpacerLeftRightLarge, SpacerTopBottomLarge, SpacerTopBottomMedium } from '../../../design/spacer/spacer';
import { FlexboxSA, FlexboxSB, FooterContactText, FooterMenuItemContainer, Left, MenuItemLeft, MenuItemRight, Right } from '../../global.styles';

// Ext-libs
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useNavigate } from 'react-router-dom';

// Int-libs


// Main
export const FooterMenuItem = (props) => {

    const [open, setOpen] = useState(false)

    const navigate = useNavigate();

    return (
        <>
            <FooterMenuItemContainer >
                <div onClick={() => { setOpen(!open) }}>
                    <MenuItemLeft>
                        {props.menuItem}
                    </MenuItemLeft>
                    <MenuItemRight>
                        {
                            open
                                ?
                                <ArrowDropUpIcon
                                    fontSize='large'
                                />
                                :
                                <ArrowDropDownIcon
                                    fontSize='large'
                                />
                        }
                    </MenuItemRight>
                </div>
                {
                    open
                    &&
                    <>
                        <SpacerTopBottomMedium>
                            <a href={props.anchorLink1} target="_blank" style={{ textDecoration: 'none' }}>
                                <FooterContactText style={{ textAlign: 'center' }} onClick={() => { navigate(props.link1) }}>
                                    {props.linkItem1}
                                </FooterContactText>
                            </a>
                        </SpacerTopBottomMedium>
                        <SpacerTopBottomMedium>
                            <a href={props.anchorLink2} target="_blank" style={{ textDecoration: 'none' }}>
                                <FooterContactText style={{ textAlign: 'center' }} onClick={() => { navigate(props.link2) }}>
                                    {props.linkItem2}
                                </FooterContactText>
                            </a>
                        </SpacerTopBottomMedium>
                        <SpacerTopBottomMedium>
                            <a href={props.anchorLink3} target="_blank" style={{ textDecoration: 'none' }}>
                                <FooterContactText style={{ textAlign: 'center' }} onClick={() => { navigate(props.link3) }}>
                                    {props.linkItem3}
                                </FooterContactText>
                            </a>
                        </SpacerTopBottomMedium>
                        <SpacerTopBottomMedium>
                            <a href={props.anchorLink4} target="_blank" style={{ textDecoration: 'none' }}>
                                <FooterContactText style={{ textAlign: 'center' }} onClick={() => { navigate(props.link4) }}>
                                    {props.linkItem4}
                                </FooterContactText>
                            </a>
                        </SpacerTopBottomMedium>
                        <SpacerTopBottomMedium>
                            <a href={props.anchorLink5} target="_blank" style={{ textDecoration: 'none' }}>
                                <FooterContactText style={{ textAlign: 'center' }} onClick={() => { navigate(props.link5) }}>
                                    {props.linkItem5}
                                </FooterContactText>
                            </a>
                        </SpacerTopBottomMedium>
                    </>
                }
            </FooterMenuItemContainer>
        </>
    );
};