// Defaults
import React, { useState, useEffect } from 'react';

// Ext-libs
import axios from 'axios';
import { Button } from '@mui/material';
import { Flex, Flexbox, FlexboxSB, FormBackground, FormCircle, FormContainer, FormImageCanvas, FormImagePen, FormLine, GetStartedFlex, GetStartedFlexbox, GetStartedInput, GetStartedLabel, GetStartedSubHeading, Sandwich, SubHeading } from '../../global.styles';
import { SpacerTopBottomLarge } from '../../../design/spacer/spacer';
import { CtaInput, CtaLabel } from '../../../pages/home/components/home.styles';

// Int-libs
import CanvasImage from '../../../assets/images/canvas.png'
import PenImage from '../../../assets/images/pen.png'

// Main
export const CtaFormP2 = ({formData, setFormData, ...props}) => {
    return (
        <>
            <FormBackground>
                <FormContainer>
                    <FormImageCanvas src={CanvasImage} alt={"Canvas"} />
                    <FormImagePen src={PenImage} alt={"Pen"} />
                    <Flexbox>
                        <Flexbox style={{ margin: '0 auto' }}>
                            <FormCircle style={{ backgroundColor: '#51B960' }} />
                            <FormLine />
                            <FormCircle style={{ backgroundColor: '#FFE202' }} />
                            <FormLine />
                            <FormCircle />
                        </Flexbox>
                    </Flexbox>
                    <Sandwich>
                        <GetStartedSubHeading>
                            How can we help ?
                        </GetStartedSubHeading>
                    </Sandwich>
                    <SpacerTopBottomLarge>
                        <GetStartedFlexbox>
                            <GetStartedLabel>Services:</GetStartedLabel>
                            <GetStartedInput
                                id="outlined-basic"
                                type='text'
                                variant="outlined"
                                value={formData.services}
                                onChange={(e) => setFormData({...formData, services: e.target.value})}
                            />
                        </GetStartedFlexbox>
                    </SpacerTopBottomLarge>
                    <SpacerTopBottomLarge>
                        <GetStartedFlex>
                            <GetStartedLabel>Describe the project:</GetStartedLabel>
                            <GetStartedInput
                                multiline
                                rows={5}
                                maxRows={7}
                                type='text'
                                value={formData.describe}
                                onChange={(e) => setFormData({...formData, describe: e.target.value})}
                            />
                        </GetStartedFlex>
                    </SpacerTopBottomLarge>
                    <FlexboxSB>
                        <Button
                            variant="outlined"
                            color='warning'
                            size='large'
                            onClick={props.prev}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: '#51B960' }}
                            size='large'
                            onClick={props.next}
                        >
                            Next
                        </Button>
                    </FlexboxSB>
                </FormContainer>
            </FormBackground>
        </>
    );
};