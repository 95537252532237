import React from 'react'
import { Container } from '../../global/global.styles'
import { Helmet } from "react-helmet";

const Cookies = () => {
  return (
    <Container>
      <Helmet>
        <title>Sri Sai Engineers | Cookies</title>
      </Helmet>
      
    </Container>
  )
}

export default Cookies