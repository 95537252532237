// Defaults
import React from 'react';
import ScrollToTop from '../../../global/components/scrollToTop/scrollToTop';

// Ext-libs


// Int-libs
import NotFoundImage from '../../../assets/images/404Img.png'
import { Container, PageExit } from '../../../global/global.styles';
import { useNavigate } from 'react-router-dom';
import { NFPCover, NFPImage, NFPImageContainer } from './components/notFoundPage.styles';

// Main
export const NotFound = () => {

    const navigate = useNavigate()

    return (
        <>
            <ScrollToTop />
            <NFPCover>
                <NFPImageContainer>
                    <NFPImage
                        src={NotFoundImage}
                        alt="Error Page not found"
                    />
                </NFPImageContainer>
            </NFPCover>
            <PageExit onClick={() => { navigate("/") }}>
                Return to home
            </PageExit>
        </>
    );
};