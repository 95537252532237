import React from 'react';

import { Routes, Route } from "react-router-dom";
import { CtaForm } from './global/components/ctaForm/ctaForm';
import { CtaFormP1 } from './global/components/ctaForm/ctaFormP1';
import { CtaFormP2 } from './global/components/ctaForm/ctaFormP2';
import { CtaFormP3 } from './global/components/ctaForm/ctaFormP3';
import ScrollToTop from './global/components/scrollToTop/scrollToTop';
import { About } from './pages/about/about';
import { CaseStudy } from './pages/caseStudy/caseStudy';
import { Contact } from './pages/contact/contact';
import { Home } from './pages/home/home';
import { NotFound } from './pages/others/404/notFound';
import Attribution from './pages/others/attribution';
import Cookies from './pages/others/cookies';
import PrivacyPolicy from './pages/others/privacyPolicy';
import Terms from './pages/others/terms';
import { Projects } from './pages/projects/projects';

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/GetStarted" element={<CtaForm />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:projectId" element={<CaseStudy />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/attribution" element={<Attribution />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
