// Defaults
import React from 'react';


// Ext-libs


// Int-libs
import { SpacerTopBottomLarge } from '../../../design/spacer/spacer';
import { Navbar } from '../../../global/components/navbar/navbar';
import { Headline, SubCaption } from '../../../global/global.styles';
import { AboveCenter, AboveCenterNav, HeroImage, HeroImageContainer, Layer, MainCTAButton } from './home.styles';
import { useLocation } from 'react-router-dom';
import useWindowDimensions from '../../../global/components/screenSize/screenSize';
import tabHeroImage from '../../../assets/images/heroImageTab.jpg'

// Main
export const HeroSection = (props) => {

    const location = useLocation();

    const { height, width } = useWindowDimensions();

    return (
        <>
            <HeroImageContainer>
                <AboveCenterNav>
                    <Navbar
                        currentPage={location.pathname}
                    />
                </AboveCenterNav>
                <AboveCenter>
                    <SubCaption>We Build Your</SubCaption>
                    <Headline>DREAMS</Headline>
                    <SpacerTopBottomLarge>
                        <MainCTAButton
                            variant="contained"
                            disableElevation
                            className={'customStyle'}
                            onClick={props.click}
                        >
                            Get Started
                        </MainCTAButton>
                    </SpacerTopBottomLarge>
                </AboveCenter>
                <Layer />
            </HeroImageContainer>
        </>
    );
};