export const colors = {
  primary: {
    yellow: "#FFEC5A",
    yellow20: "#FFF9CC",
    green: "#69FF6F",
    green20: "#CDFFCC"
  },
  secondary: {
    red: "#FF6363",
    red20: "#FFCACA",
    orange: "#FFAB76",
    orange20: "#FDE2D1"
  },
  accents: {
    red: "#FE8668",
    orange: "#FDBC1F",
    teal: "#30D6B0",
    indigo: "#4269F2"
  },
  ui: {
    black: "#000000",
    grey80: "#3A3A3A",
    grey60: "#A6A6AA",
    grey40: "#CBCBD4",
    grey20: "#F8F7FA",
    white: "#FFFFFF"
  },
  bg: {
    white: "#FFFFFF",
    grey20: "#F8F7FA",
  },
  text: {
    black: "#000000",
    grey80: "#3A3A3A",
    grey60: "#A6A6AA",
    grey40: "#CBCBD4",
    grey20: "#F8F7FA",
    white: "#FFFFFF",
    error: "#FE8668",
    success: "#51B960",
    link: "#5FA1D5",
    highlight: "#FFE202",
  },
};