// Defaults
import React, { useState } from 'react';

// Ext-libs
import { CtaFormSuccess } from './ctaFormSuccess';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../../database/firebase';
import { useNavigate } from "react-router-dom";

// Int-libs
import { CtaFormP1 } from './ctaFormP1';
import { CtaFormP2 } from './ctaFormP2';
import { CtaFormP3 } from './ctaFormP3';

// Main
export const CtaForm = () => {

    const [page, setPage] = useState(0)

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        contactNum: "",
        services: "",
        describe: "",
        medium: "",
        dayPreference: "",
        timePreference: ""
    })

    const PageDisplay = () => {
        if (page === 0) {
            return <CtaFormP1
                formData={formData}
                setFormData={setFormData}
                next={() => {
                    setPage((currentPage) => currentPage + 1)
                }}
            />
        } else if (page === 1) {
            return <CtaFormP2
                formData={formData}
                setFormData={setFormData}
                next={() => {
                    setPage((currentPage) => currentPage + 1)
                }}
                prev={() => {
                    setPage((currentPage) => currentPage - 1)
                }}
            />
        } else if (page === 2) {
            return <CtaFormP3
                formData={formData}
                setFormData={setFormData}
                submit={handleSubmit}
                prev={() => {
                    setPage((currentPage) => currentPage - 1)
                }}
                loading={loading}
            />
        } else {
            return <CtaFormSuccess
                name={formData.name}
            />
        }
    }

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [submission, setSubmission] = useState()

    const prospectsCollectionRef = collection(db, "prospects")

    const handleSubmit = async () => {

        setLoading(true)

        await addDoc(prospectsCollectionRef, formData)

        // await axios.post(`${url}/prospects/register`, formData)
        //     .then(() => {
        //         setLoading(false)
        //         setSubmission("success")
        //     });

        setPage((currentPage) => currentPage + 1)

        setLoading(false)
        setSubmission("success")
    }

    return (
        <>
            {PageDisplay()}
        </>
    );
};